<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <loader v-if="loaders.reading"/>

                <div v-else>

                    <!-- Breadcrumb -->
                    <breadcrumb />

                    <div v-if="pathologies.DB.length < 1" class="alert alert-warning m-0">
                        Ancora nessuna patologia
                    </div>

                    <div v-else>

                        <div class="card card-body mb-2">

                            <p class="font-weight-bold m-0">Filtri di ricerca</p>

                            <p class="text-muted mb-3">
                                <small>
                                    Compila i campi che seguono, la ricerca avverrà in automatico
                                </small>
                            </p>

                            <div class="row">
                                <div class="col-lg-3 mb-1 mb-lg-0">
                                    <input class="form-control text-monospace text-uppercase" type="text" v-model="filters.pathology.id" placeholder="Identificativo">
                                </div>
                                <div class="col-lg-3 mb-1 mb-lg-0">
                                    <input class="form-control" type="text" v-model="filters.pathology.title" placeholder="Titolo">
                                </div>
                                <div class="col-lg-3 mb-1 mb-lg-0">
                                    <input class="form-control" type="text" v-model="filters.pathology.type" placeholder="Tipologia">
                                </div>
                                <div class="col-lg-3 mb-1 mb-lg-0">
                                    <input class="form-control" type="text" v-model="filters.pathology.group" placeholder="Gruppo">
                                </div>
                            </div>

                        </div>

                        <div class="card card-body" v-show="pathologiesFiltered.length < 1">
                            <div class="alert alert-warning m-0">
                                Nessuna patologia con i filtri di ricerca impostati
                            </div>
                        </div>

                        <div class="card card-body card-table" v-show="pathologiesFiltered.length > 0">
                            <div class="table-responsive">
                                <table class="table table-bordered table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                ID
                                            </th>
                                            <th scope="col">
                                                Titolo
                                            </th>
                                            <th scope="col">
                                                Tipologia
                                            </th>
                                            <th scope="col">
                                                Gruppo
                                            </th>
                                            <th scope="col">
                                                Visibile
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <router-link tag="tr" :to="`/user/patologie/modifica/${pathology.title}`" v-for="pathology of pathologiesFiltered" :key="`pathology_table_${pathology.id}`" class="hover cursor-pointer" v-bind:class="{'disabled_invisible': functionDisabled(7, 'read')}">
                                            <td width="1%" class="text-monospace font-weight-bold">
                                                {{pathology.id_pathology}}
                                            </td>
                                            <td width="40%">
                                                {{pathology.title}}
                                            </td>
                                            <td width="10%" class="text-truncate">
                                                {{pathology.pathology_type.title}}
                                            </td>
                                            <td width="1%">
                                                {{pathology.pathology_type.pathology_group.title}}
                                            </td>
                                            <td width="1%" class="text-center" :class="{'text-success': pathology.state, 'text-danger': !pathology.state}">
                                                {{pathology.state ? "SI" : "NO"}}
                                            </td>
                                        </router-link>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
                
            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <router-link class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom" to="/user/patologie/aggiungi" v-bind:class="{'disabled': functionDisabled(7, 'create')}">
                            <span class="d-none d-md-block">Aggiungi patologia</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import store from '@/store'

    import {
        orderBy,
        filter
    } from 'lodash'

    export default {
        data() {
            return {
                pathologies: {
                    DB: []
                },
                loaders: {
                    reading: true
                },
                filters: {
                    pathology: {
                        id:    "",
                        title: "",
                        type:  "",
                        group: ""
                    }
                }
            }
        },
        methods: {
            async readPathologies() {

                try {
                    const patologie = await this.axios.get(`user/pathologies/read/all`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.pathologies.DB = patologie.data
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   'error'
                    })

                }
                finally {
                    this.loaders.reading = false
                }

            },
            functionDisabled(id_function, action) {
                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                }
            }
        },
        mounted() {
            this.readPathologies()
        },
        computed: {
            pathologiesFiltered() {
                return filter(
                    orderBy(
                        this.pathologies.DB,
                        'id_pathology'
                    ),
                    o => {
                        return (
                            o.id_pathology.toUpperCase().includes(
                                this.filters.pathology.id.toUpperCase()
                            ) &&
                            o.title.toUpperCase().includes(
                                this.filters.pathology.title.toUpperCase()
                            ) &&
                            o.pathology_type.title.toUpperCase().includes(
                                this.filters.pathology.type.toUpperCase()
                            ) &&
                            o.pathology_type.pathology_group.title.toUpperCase().includes(
                                this.filters.pathology.group.toUpperCase()
                            )
                        )
                    }
                )
            }
        }
    }

</script>