<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />

                <div class="card card-body">

                    <h5 class="font-weight-bold m-0">Associa il paziente ad una patologia</h5>

                    <p class="text-muted mb-3">
                        <small>
                            In questa schermata puoi associare il paziente ad una patologia ed impostarne la durata degli appuntamenti
                        </small>
                    </p>

                    <form @submit.prevent="createPathologies_therapies" autocomplete="off">

                        <div class="row">
                            <div class="col-xl-3 col-lg-6">
                                <div class="form-group">

                                    <label for="CF">Codice fiscale</label>
                                    <input type="text" class="form-control f-family-monospace" id="CF" :value="this.$route.params.CF" disabled>

                                </div>
                                <div class="form-group">

                                    <label for="sessions_duration">Durata dell'appuntamento</label>
                                    <input type="number" class="form-control" id="sessions_duration" min="15" v-model.trim="$v.form.sessions_duration.$model" v-bind:class="{'is-invalid': (!$v.form.sessions_duration.required || !$v.form.sessions_duration.minValue) && $v.form.sessions_duration.$dirty}">

                                    <small class="form-text text-muted" v-show="!$v.form.sessions_duration.$dirty">
                                        Quantità in minuti
                                    </small>
                                    <div class="alert alert-info mt-3">
                                        La durata è utile per impostare automaticamente la fine di un appuntamento, potrà essere gestita solo da chi ne avrà l'autorizzazione
                                    </div>
                                    
                                    <p class="text-danger m-0" v-show="(!$v.form.sessions_duration.required || !$v.form.sessions_duration.minValue) && $v.form.sessions_duration.$dirty">
                                        <small>
                                            Impostare almeno 15 minuti
                                        </small>
                                    </p>
                                    <p class="text-danger m-0" v-show="!$v.form.sessions_duration.required && $v.form.sessions_duration.$dirty">
                                        <small>
                                            Durata della seduta richiesto
                                        </small>
                                    </p>

                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6">
                                <div class="form-group">

                                    
                                    <label for="pathologies" class="m-0">Seleziona una patologia</label>

                                    <input type="text" placeholder="🔎︎ Filtra..." class="search_filter w-100 mb-1" v-model="filters.pathology_input_search" @input="show_pathologies">

                                    <div class="h-max-160-px" style="overflow-y: auto; overflow-x: hidden;">
                                        <div class="custom-control custom-radio custom-control-inline text-truncate w-100" v-for="(pathology, index) in orderedPathologies" v-bind:key="`filter_pathology_${index}`" v-bind:class="{'border-bottom': index+1 != orderedPathologies.length}">
                                    
                                            <input type="radio" class="custom-control-input" :id="`pathology_${pathology.title}`" :value="pathology.id" v-model="form.id_pathology">
                                            
                                            <label class="custom-control-label" :for="`pathology_${pathology.title}`">
                                                <b class="text-monospace">{{pathology.id_pathology}}</b> {{pathology.title}}
                                            </label>

                                        </div>
                                    </div>

                                    <!--<select class="form-control" id="pathologies" v-model="form.id_pathology" @input="reset_therapy" ref="pathologies">
                                        <option v-for="pathology of orderedPathologies" :key="`pathology_${pathology.id}`" :value="pathology.id">
                                            {{pathology.title}}
                                        </option>
                                    </select>-->

                                    <small class="form-text text-muted">
                                        Seleziona una patologia da associare al paziente
                                    </small>
                                                                 

                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-6">
                                <div class="form-group">

                                    <label for="therapies">Seleziona una terapia</label>

                                    <select class="form-control" id="therapies" v-model="form.id_therapy" :disabled="!form.id_pathology || therapies_by_pathology.length < 1" v-bind:class="{'is-invalid': !form.id_pathology || (therapies_by_pathology.length < 1 && form.id_pathology)}">
                                        <option :value="null">
                                            Nessuna terapia
                                        </option>
                                        <option v-for="pathology_therapy of therapies_by_pathology" :key="`pathology_therapy_${pathology_therapy.id}`" :value="pathology_therapy.therapy.id">
                                            {{pathology_therapy.therapy.title}}
                                        </option>
                                    </select>

                                    <div class="invalid-feedback" v-show="!form.id_pathology">
                                        Per selezionare una terapia, scegliere una patologia!
                                    </div>
                                    <div class="invalid-feedback" v-show="therapies_by_pathology.length < 1 && form.id_pathology">
                                        Non sono state associate terapie alla patologia selezionata
                                    </div>
                                    <small class="form-text text-muted">
                                        Seleziona una terapia che dovrà seguire il paziente
                                    </small>

                                    <div class="alert alert-info mt-3">
                                        La terapia potrà essere impostata anche durante la creazione/modifica di un appuntamento
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div v-show="validation.ko" class="alert alert-danger text-center">
                            {{validation.ko}}
                        </div>

                        <button class="btn btn-ifo" :disabled="loaders.creating || $v.$invalid">
                                
                            <span v-show="!loaders.creating">
                                Aggiungi
                            </span>

                            <div v-show="loaders.creating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                        </button>

                    </form>
                </div>
            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a href class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom disabled">
                            <i class="text-muted">
                                Nessuna funzione
                            </i>
                        </a>
                    </li>
                </ul>
            </div>

        </div>  
    </div>
</template>

<script>

    import { 
        required,
        minValue
    } from "vuelidate/lib/validators"

    import {
        orderBy,
        filter
    } from 'lodash'

    import moment from "moment"

    export default {
        data() {
            return {
                DB: {
                    patient:   null,
                    pathologies: []
                },
                local: {
                    therapy:     {},
                    pathologies: []
                },
                form: {
                    id_therapy:        null,
                    id_pathology:      null,
                    //sessions_quantity: null,
                    sessions_duration: 20
                },
                validation: {
                    ko: false
                },
                loaders: {
                    creating: false,
                    reading:  true
                },
                utility: {
                    today: moment().format("YYYY-MM-DD")
                },
                filters: {
                    pathology_input_search: ""
                }
            }
        },
        validations() {
            return {
                form: {
                    /*sessions_quantity: {
                        required,
                        minValue: minValue(1)
                    },*/
                    sessions_duration: {
                        required,
                        minValue: minValue(15)
                    },
                    id_pathology: {
                        required
                    }
                }
            }
        },
        methods: {

            async createPathologies_therapies() {

                this.loaders.creating = true
                this.validation.ko    = false
                this.form.CF          = this.$route.params.CF

                try {

                    const patient_pathology = await this.axios.post(`user/patients_pathologies/create`, this.form, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: patient_pathology.data.msg,
                        type:   `success`
                    })

                    this.$router.go(-1)

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko    = message
                    this.loaders.creating = false

                }
                
            },
            async readPatient() {

                const {
                    CF
                } = this.$route.params

                try {

                    const patient = await this.axios.get(`user/patients/read/detail/${CF}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.patient = patient.data

                    this.readPathologies(patient.data.patients_pathologies)

                }
                catch(e) {

                    this.$toast.open({
                        message: `Si è verificato un problema, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}` ,
                        type:    `error`
                    })

                    this.$router.go(-1)

                }
                finally {
                    this.loaders.reading = false
                }

            },
            async readPathologies() {

                try {

                    const pathologies = await this.axios.get(`user/pathologies/read/actives`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.pathologies = pathologies.data

                }
                catch(e) {

                    this.$toast.open({
                        message: `Si è verificato un problema, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}` ,
                        type:    `error`
                    })

                    this.$router.go(-1)

                }
                finally {
                    this.loaders.reading = false
                }

            },

            reset_therapy() {
                this.form.id_therapy = null
            }
            
        },
        mounted() {
            this.readPatient()
        },
        computed: {
            therapies_by_pathology() {

                let pathologies_therapies = []

                for(const pathology of this.DB.pathologies) {
                    if(pathology.id == this.form.id_pathology) pathologies_therapies = pathology.pathologies_therapies
                }

                return pathologies_therapies
            },
            orderedPathologies() {
                return filter(
                    orderBy(
                        this.DB.pathologies,
                        'id_pathology'
                    ),
                    o => {
                        return (
                            o.title.toUpperCase().includes(
                                this.filters.pathology_input_search.toUpperCase()
                            )
                        )
                    }
                )
            },
        }
    }

</script>