<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <loader v-if="loaders.reading" />

                <div v-else>

                    <!-- Breadcrumb -->
                    <breadcrumb />

                    <div class="card card-body mb-3">
                        <!--@submit.prevent="updateRoom"  -->
                        <form autocomplete="off">

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="title">Titolo</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="title" v-model.trim="$v.form.title.$model" v-bind:class="{'is-invalid': !$v.form.title.required && $v.form.title.$dirty}" required disabled>                           
                                        </div>
                                        <p v-if="!$v.form.title.required && $v.form.title.$dirty" class="m-0 text-danger">
                                            <small>
                                                Titolo richiesto
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="form-group">
                                        <label for="title">Numero</label>
                                        <div class="input-group">
                                            <input type="number" class="form-control" id="number" v-model.number="$v.form.number.$model" v-bind:class="{'is-invalid': !$v.form.number.required && $v.form.number.$dirty}" required disabled>                           
                                        </div>
                                        <p v-if="!$v.form.number.required && $v.form.number.$dirty" class="m-0 text-danger">
                                            <small>
                                                Numero richiesto
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="form-group">
                                        <label for="color">Colore unico della stanza</label>
                                        <div class="input-group">
                                            <input type="color" class="form-control" id="color" v-model.trim="$v.form.color.$model" v-bind:class="{'is-invalid': !$v.form.color.required && $v.form.color.$dirty}" required>                           
                                        </div>
                                        <p v-if="!$v.form.color.required && $v.form.color.$dirty" class="m-0 text-danger">
                                            <small>
                                                Colore richiesto
                                            </small>
                                        </p>
                                    </div>
                                </div>                                
                                <!--<div class="col-lg-3">
                                    <div class="form-group">
                                        <label for="title">Numero massime di visite giornaliero</label>
                                        <div class="input-group">
                                            <input type="number" class="form-control" id="max_visits" v-model.number="$v.form.max_visits.$model" v-bind:class="{'is-invalid': !$v.form.max_visits.required && $v.form.max_visits.$dirty}" required>                           
                                        </div>
                                        <p v-if="!$v.form.max_visits.required && $v.form.max_visits.$dirty" class="m-0 text-danger">
                                            <small>
                                                Numero massime di visite giornaliero richiesto
                                            </small>
                                        </p>
                                    </div>
                                </div> -->                              
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="description">Descrizione</label>
                                        <div class="input-group">
                                            <textarea class="form-control " id="description" v-model.trim="$v.form.description.$model"></textarea>                           
                                        </div>
                                    </div>
                                </div>
                            </div>       
                            <div class="row">
                                <div class="col-lg-12">
                                    <button @click="addAvailability" id="addAvailibility">Aggiungi disponibilità</button>                           
                                    <div class="previous hrt" v-for="(availibility, counter) in form.availibilities" v-bind:key="counter">
                                        <div class="text-right">
                                            <span @click="deleteAvailability(counter)" class="text-danger text-sm-1">Rimuovi</span>                                     
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-lg-6">                                       
                                                <div class="form-group">                                               
                                                    <label for="duration"><strong>{{counter+1}}.</strong> Dal:</label>
                                                    <div class="input-group">

                                                        <input type="date" :min="calendar.config.minDate" :max="calendar.config.maxDate" @blur="checkDateDalAl(counter)" class="form-control" v-model="availibility.dal"  required>
                                                    </div>
                                                </div>
                                                                                        
                                            </div>       
                                            <div class="col-lg-6">   
                                                <div class="form-group">          
                                                    <label for="duration">Al:</label>
                                                    <div class="input-group">
                                                        <input type="date" :min="calendar.config.minDate" :max="calendar.config.maxDate" @blur="checkDateDalAl(counter)" class="form-control" v-model="availibility.al" required> 
                                                    </div>    
                                                </div>  
                                            </div>
                                        </div>                                  
                                        <div class="row rowbg2">
                                            <div class="col-lg-3">  
                                                <label for="monday"><strong>Lunedì:</strong></label>                                     
                                                <div class="form-group">
                                                    <label for="duration">Mattina - dalle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.monday_1.am.dal" @blur="checkTime(counter, 'monday', 'am')" class="form-control">                                                
                                                    </div>
                                                </div> 
                                                <div class="form-group">
                                                    <label for="duration">Pomeriggio - dalle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.monday_1.pm.dal" @blur="checkTime(counter, 'monday', 'pm')" class="form-control">                                                
                                                    </div>
                                                </div>                                                  
                                            </div>       
                                            <div class="col-lg-3">  
                                                <label><br /></label> 
                                                <div class="form-group">          
                                                    <label for="duration">alle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.monday_1.am.al" @blur="checkTime(counter, 'monday', 'am')" class="form-control"> 
                                                    </div>    
                                                </div>  
                                                <div class="form-group">          
                                                    <label for="duration">alle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.monday_1.pm.al" @blur="checkTime(counter, 'monday', 'pm')" class="form-control"> 
                                                    </div>    
                                                </div>                                           
                                            </div>

                                            <div class="col-lg-3">  
                                                <label for="monday"><strong>Martedì:</strong></label>                                     
                                                <div class="form-group">
                                                    <label for="duration">Mattina - dalle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.tuesday_2.am.dal" @blur="checkTime(counter, 'tuesday', 'am')" class="form-control">                                                
                                                    </div>
                                                </div> 
                                                <div class="form-group">
                                                    <label for="duration">Pomeriggio - dalle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.tuesday_2.pm.dal" @blur="checkTime(counter, 'tuesday', 'pm')" class="form-control">                                                
                                                    </div>
                                                </div>                                                  
                                            </div>       
                                            <div class="col-lg-3">  
                                                <label><br /></label> 
                                                <div class="form-group">          
                                                    <label for="duration">alle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.tuesday_2.am.al" @blur="checkTime(counter, 'tuesday', 'am')" class="form-control"> 
                                                    </div>    
                                                </div>  
                                                <div class="form-group">          
                                                    <label for="duration">alle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.tuesday_2.pm.al" @blur="checkTime(counter, 'tuesday', 'pm')" class="form-control"> 
                                                    </div>    
                                                </div>                                           
                                            </div>                                       
                                        </div>   
                                        
                                        <div class="row rowbg">
                                            <div class="col-lg-3">  
                                                <label for="monday"><strong>Mercoledì:</strong></label>                                     
                                                <div class="form-group">
                                                    <label for="duration">Mattina - dalle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.wednesday_3.am.dal" @blur="checkTime(counter, 'wednesday', 'am')" class="form-control">                                                
                                                    </div>
                                                </div> 
                                                <div class="form-group">
                                                    <label for="duration">Pomeriggio - dalle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.wednesday_3.pm.dal" @blur="checkTime(counter, 'wednesday', 'pm')" class="form-control">                                                
                                                    </div>
                                                </div>                                                  
                                            </div>       
                                            <div class="col-lg-3">  
                                                <label><br /></label> 
                                                <div class="form-group">          
                                                    <label for="duration">alle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.wednesday_3.am.al"  @blur="checkTime(counter, 'wednesday', 'am')" class="form-control"> 
                                                    </div>    
                                                </div>  
                                                <div class="form-group">          
                                                    <label for="duration">alle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.wednesday_3.pm.al"  @blur="checkTime(counter, 'wednesday', 'pm')" class="form-control"> 
                                                    </div>    
                                                </div>                                           
                                            </div>

                                            <div class="col-lg-3">  
                                                <label for="monday"><strong>Giovedì:</strong></label>                                     
                                                <div class="form-group">
                                                    <label for="duration">Mattina - dalle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.thursday_4.am.dal"  @blur="checkTime(counter, 'thursday', 'am')" class="form-control">                                                
                                                    </div>
                                                </div> 
                                                <div class="form-group">
                                                    <label for="duration">Pomeriggio - dalle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.thursday_4.pm.dal" @blur="checkTime(counter, 'thursday', 'pm')" class="form-control">                                                
                                                    </div>
                                                </div>                                                  
                                            </div>       
                                            <div class="col-lg-3">  
                                                <label><br /></label> 
                                                <div class="form-group">          
                                                    <label for="duration">alle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.thursday_4.am.al" @blur="checkTime(counter, 'thursday', 'am')" class="form-control"> 
                                                    </div>    
                                                </div>  
                                                <div class="form-group">          
                                                    <label for="duration">alle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.thursday_4.pm.al" @blur="checkTime(counter, 'thursday', 'pm')" class="form-control"> 
                                                    </div>    
                                                </div>                                           
                                            </div>                                       
                                        </div>
                                        
                                        <div class="row rowbg2 hr">
                                            <div class="col-lg-3">  
                                                <label for="monday"><strong>Venerdì:</strong></label>                                     
                                                <div class="form-group">
                                                    <label for="duration">Mattina - dalle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.friday_5.am.dal" @blur="checkTime(counter, 'friday', 'am')"  class="form-control">                                                
                                                    </div>
                                                </div> 
                                                <div class="form-group">
                                                    <label for="duration">Pomeriggio - dalle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.friday_5.pm.dal" @blur="checkTime(counter, 'friday', 'pm')"  class="form-control">                                                
                                                    </div>
                                                </div>                                                  
                                            </div>       
                                            <div class="col-lg-3">  
                                                <label><br /></label> 
                                                <div class="form-group">          
                                                    <label for="duration">alle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.friday_5.am.al" @blur="checkTime(counter, 'friday', 'am')"  class="form-control"> 
                                                    </div>    
                                                </div>  
                                                <div class="form-group">          
                                                    <label for="duration">alle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.friday_5.pm.al" @blur="checkTime(counter, 'friday', 'pm')"  class="form-control"> 
                                                    </div>    
                                                </div>                                           
                                            </div>

                                            <div class="col-lg-3">  
                                                <label for="monday"><strong>Sabato:</strong></label>                                     
                                                <div class="form-group">
                                                    <label for="duration">Mattina - dalle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.saturday_6.am.dal" @blur="checkTime(counter, 'saturday', 'am')"  class="form-control">                                                
                                                    </div>
                                                </div> 
                                                <div class="form-group">
                                                    <label for="duration">Pomeriggio - dalle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.saturday_6.pm.dal" @blur="checkTime(counter, 'saturday', 'pm')"  class="form-control">                                                
                                                    </div>
                                                </div>                                                  
                                            </div>       
                                            <div class="col-lg-3">  
                                                <label><br /></label> 
                                                <div class="form-group">          
                                                    <label for="duration">alle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.saturday_6.am.al" @blur="checkTime(counter, 'saturday', 'am')"  class="form-control"> 
                                                    </div>    
                                                </div>  
                                                <div class="form-group">          
                                                    <label for="duration">alle ore:</label>
                                                    <div class="input-group">
                                                        <input type="time" v-model="availibility.saturday_6.pm.al" @blur="checkTime(counter, 'saturday', 'pm')"  class="form-control"> 
                                                    </div>    
                                                </div>                                           
                                            </div>                                       
                                        </div>                                                     
                                    </div>                               
                                </div>
                            </div>                     

                            <div v-show="validation.ko" class="alert alert-danger text-center">
                                {{validation.ko}}
                            </div>
                            <!--button  || !$v.$anyDirty  -->    
                            <button v-if="!functionDisabled(2, 'update')" @click="updateRoom" class="btn btn-ifo" :disabled="$v.$invalid || loaders.updating || form.hour_close < form.hour_open">
                                    
                                <span v-show="!loaders.updating">
                                    Modifica
                                </span>

                                <div v-show="loaders.creating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                            </button>

                        </form>

                    </div>
                    
                </div>

            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a v-b-toggle.elimina v-bind:class="{'disabled': loaders.deleting || functionDisabled(2, 'delete')}" class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom">
                            <span class="d-none d-md-block">Elimina</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </a>
                        <b-collapse id="elimina">
                            <div class="alert alert-danger m-0">
                                Sicuro di voler eliminare questa stanza?
                            </div>
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <button @click="deleteRoom" class="btn btn-ifo btn-block btn-sm rounded-0" :disabled="loaders.deleting">
                                    
                                        <span v-show="!loaders.deleting">
                                            Si
                                        </span>

                                        <div v-show="loaders.deleting" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>
                                </div>
                                <div class="col-6 pl-0">
                                    <button v-b-toggle.elimina class="btn btn-ifo btn-block btn-sm rounded-0">No</button>
                                </div>
                            </div>
                        </b-collapse>
                    </li>
                    <li class="nav-item">
                        <a @click="suspendRoom" v-bind:class="{'disabled': loaders.suspending || functionDisabled(2, 'update')}" class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom">
                            
                            <span class="d-none d-md-block" v-if="!loaders.suspending && form.state">Rendi non visibile</span> <svg v-show="!loaders.suspending && form.state" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause-circle ml-md-2" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                            </svg>
                        
                            <span class="d-none d-md-block" v-if="!loaders.suspending && !form.state">Rendi visibile</span> <svg v-show="!loaders.suspending && !form.state" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle ml-md-2" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                            </svg>

                            <div v-show="loaders.suspending" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required
    } from "vuelidate/lib/validators"

    import moment from "moment"
    import store  from "@/store"

    export default {
        data() {
            return {
                form: {
                    title:       null,
                    number:      null,
                    color:       `#ffad08`,
                    description: null,
                    hour_open:   null,
                    hour_close:  null,
                    availibilities: [{
                        dal:'',
                        al: '',
                        monday_1: {
                            am: {
                                dal: '',
                                al: ''
                            },
                            pm: {
                                dal: '',
                                al: ''
                            }                                                   
                        },
                        tuesday_2: {
                            am: {
                                dal: '',
                                al: ''
                            },
                            pm: {
                                dal: '',
                                al: ''
                            }                                                   
                        },
                        wednesday_3: {
                            am: {
                                dal: '',
                                al: ''
                            },
                            pm: {
                                dal: '',
                                al: ''
                            }                                                   
                        },
                        thursday_4: {
                            am: {
                                dal: '',
                                al: ''
                            },
                            pm: {
                                dal: '',
                                al: ''
                            }                                                   
                        } ,
                        friday_5: {
                            am: {
                                dal: '',
                                al: ''
                            },
                            pm: {
                                dal: '',
                                al: ''
                            }                                                   
                        } ,
                        saturday_6: {
                            am: {
                                dal: '',
                                al: ''
                            },
                            pm: {
                                dal: '',
                                al: ''
                            }                                                   
                        }                                                                                                                                                                     
                    }]                                    
                },
                validation: {
                    ko: false,
                    conflict:  false
                },
                loaders: {
                    reading:    true,
                    updating:   false,
                    deleting:   false,
                    suspending: false
                },
                utility: {
                    today: moment().format("YYYY-MM-DD")
                },
                calendar: {
                    config: {
                        minDate:      moment().format("YYYY-MM-DD"),
                        maxDate:      null,
                        timeFrom:     8*60,
                        timeTo:       20*60,
                        timeStep:     30,
                        roomSelected: {},
                        events:       [],
                        hideWeekDays: [],
                        selectedDate: null,
                        specialHours: null
                    }
                },                             
            }
        },
        validations() {
            return {
                form: {
                    title: {
                        required
                    },
                    color: {
                        required
                    },                   
                    number: {
                        required
                    },               
                    description: {

                    }                               
                }
            }
        },
        methods: {
            addAvailability(){
                this.form.availibilities.push({
                    dal:'',
                    al: '',
                    monday_1: {
                        am: {
                            dal: '',
                            al: ''
                        },
                        pm: {
                            dal: '',
                            al: ''
                        }                                                   
                    },
                    tuesday_2: {
                        am: {
                            dal: '',
                            al: ''
                        },
                        pm: {
                            dal: '',
                            al: ''
                        }                                                   
                    },
                    wednesday_3: {
                        am: {
                            dal: '',
                            al: ''
                        },
                        pm: {
                            dal: '',
                            al: ''
                        }                                                   
                    },
                    thursday_4: {
                        am: {
                            dal: '',
                            al: ''
                        },
                        pm: {
                            dal: '',
                            al: ''
                        }                                                   
                    } ,
                    friday_5: {
                        am: {
                            dal: '',
                            al: ''
                        },
                        pm: {
                            dal: '',
                            al: ''
                        }                                                   
                    } ,
                    saturday_6: {
                        am: {
                            dal: '',
                            al: ''
                        },
                        pm: {
                            dal: '',
                            al: ''
                        }                                                   
                    }                        
                })
            },
            deleteAvailability(counter){
                this.form.availibilities.splice(counter,1);   
            }, 
            checkDateDalAl(counter) {

                const date_dal   = moment(this.form.availibilities[counter].dal).format("YYYYMMDD")
                const date_al   = moment(this.form.availibilities[counter].al).format("YYYYMMDD")    

                const conflitto = (
                    date_dal > date_al
                ) 

                if(conflitto) {

                    const message = "Data disponibilità DAL maggiore di data AL"

                    this.$toast.open({
                        message,
                        type: "warning"
                    })                   
                    this.validation.conflict = message
                    this.validation.ko    = message
                    this.loaders.updating = true                   
                } else {
                    this.validation.conflict = false
                    this.validation.ko    = false 
                    this.loaders.updating = false                  
                }
            },            
            checkTime(counter, dayweek, moraft) {
                var time_dal;
                var time_al;

                var nameDay;
                var namemoraft;

                if(dayweek == "monday" && moraft == "am" && this.form.availibilities[counter].monday_1.am.al != ""){
                    time_dal = this.form.availibilities[counter].monday_1.am.dal; 
                    time_al = this.form.availibilities[counter].monday_1.am.al; 
                    nameDay = "lunedì";
                    namemoraft = "mattina";
                } else if(dayweek == "monday" && moraft == "pm" && this.form.availibilities[counter].monday_1.pm.al != "") {
                    time_dal = this.form.availibilities[counter].monday_1.pm.dal; 
                    time_al = this.form.availibilities[counter].monday_1.pm.al; 
                    nameDay = "lunedì";
                    namemoraft = "pomeriggio";                   
                } else if(dayweek == "tuesday" && moraft == "am" && this.form.availibilities[counter].tuesday_2.am.al != ""){
                    time_dal = this.form.availibilities[counter].tuesday_2.am.dal; 
                    time_al = this.form.availibilities[counter].tuesday_2.am.al; 
                    nameDay = "martedì";
                    namemoraft = "mattina";                   
                } else if(dayweek == "tuesday" && moraft == "pm" && this.form.availibilities[counter].tuesday_2.pm.al != "") {
                    time_dal = this.form.availibilities[counter].tuesday_2.pm.dal; 
                    time_al = this.form.availibilities[counter].tuesday_2.pm.al; 
                    nameDay = "martedì";
                    namemoraft = "pomeriggio";                        
                } else if(dayweek == "wednesday" && moraft == "am" && this.form.availibilities[counter].wednesday_3.am.al != ""){
                    time_dal = this.form.availibilities[counter].wednesday_3.am.dal; 
                    time_al = this.form.availibilities[counter].wednesday_3.am.al; 
                    nameDay = "mercoledì";
                    namemoraft = "mattina";                     
                } else if(dayweek == "wednesday" && moraft == "pm" && this.form.availibilities[counter].wednesday_3.pm.al != "") {
                    time_dal = this.form.availibilities[counter].wednesday_3.pm.dal; 
                    time_al = this.form.availibilities[counter].wednesday_3.pm.al; 
                    nameDay = "mercoledì";
                    namemoraft = "pomeriggio";                     
                } else if(dayweek == "thursday" && moraft == "am" && this.form.availibilities[counter].thursday_4.am.al != ""){
                    time_dal = this.form.availibilities[counter].thursday_4.am.dal; 
                    time_al = this.form.availibilities[counter].thursday_4.am.al; 
                    nameDay = "giovedì";
                    namemoraft = "mattina";                    
                } else if(dayweek == "thursday" && moraft == "pm" && this.form.availibilities[counter].thursday_4.pm.al != "") {
                    time_dal = this.form.availibilities[counter].thursday_4.pm.dal; 
                    time_al = this.form.availibilities[counter].thursday_4.pm.al; 
                    nameDay = "giovedì";
                    namemoraft = "pomeriggio";                     
                } else if(dayweek == "friday" && moraft == "am" && this.form.availibilities[counter].friday_5.am.al != ""){
                    time_dal = this.form.availibilities[counter].friday_5.am.dal; 
                    time_al = this.form.availibilities[counter].friday_5.am.al; 
                    nameDay = "venerdì";
                    namemoraft = "mattina";                     
                } else if(dayweek == "friday" && moraft == "pm" && this.form.availibilities[counter].friday_5.pm.al != "") {
                    time_dal = this.form.availibilities[counter].friday_5.pm.dal; 
                    time_al = this.form.availibilities[counter].friday_5.pm.al; 
                    nameDay = "venerdì";
                    namemoraft = "pomeriggio";                       
                } else if(dayweek == "saturday" && moraft == "am" && this.form.availibilities[counter].saturday_6.am.al != ""){
                    time_dal = this.form.availibilities[counter].saturday_6.am.dal; 
                    time_al = this.form.availibilities[counter].saturday_6.am.al; 
                    nameDay = "sabato";
                    namemoraft = "mattina";                      
                } else if(dayweek == "saturday" && moraft == "pm" && this.form.availibilities[counter].saturday_6.pm.al != "") {
                    time_dal = this.form.availibilities[counter].saturday_6.pm.dal; 
                    time_al = this.form.availibilities[counter].saturday_6.pm.al; 
                    nameDay = "sabato";
                    namemoraft = "pomeriggio";                      
                }                    

                time_dal = time_dal.replace(':', '');
                time_al = time_al.replace(':', '');

                const conflitto = (
                    time_dal > time_al
                ) 

                if(conflitto) {
                    const message = "Orario "+ nameDay + " " + namemoraft + " errato"
                    this.$toast.open({
                        message,
                        type: "warning"
                    })                   
                    this.validation.conflict = message
                    this.validation.ko    = message
                    this.loaders.updating = true                   
                } else {
                    this.validation.conflict = false
                    this.validation.ko    = false 
                    this.loaders.updating = false                  
                }
            },                                                                                                                  
            async updateRoom() {

                this.loaders.updating = true

                try {

                    const room = await this.axios.post(`user/rooms/update`, this.form,
                    {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: room.data.msg,
                        type:   `success`
                    })
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.updating = false
                }
                
                
            },
            async readRoom() {

                try {

                    const room = await this.axios.get(`user/rooms/read/detail/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form = room.data
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                    this.$router.go(-1)

                }
                finally {
                    this.loaders.reading = false
                }

            },
            async deleteRoom() {

                this.loaders.deleting = true

                try {

                    const res = await this.axios.delete(`user/rooms/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   `success`
                    })

                    this.$router.push(`/user/stanze`)
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   'error'
                    })

                    this.loaders.deleting = false

                }

            },
            async suspendRoom() {

                this.loaders.suspending = true

                try {

                    const res = await this.axios.get(`user/rooms/suspend/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })

                    this.form.state = !this.form.state
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   'error'
                    })

                }
                finally {
                    this.loaders.suspending = false
                }

            },
            functionDisabled(id_function, action) {
                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                }
            }
        },
        mounted() {
            this.readRoom()
        }
    }

</script>