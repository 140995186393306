<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <loader v-if="loaders.reading" />

                <div v-else>

                    <!-- Breadcrumb -->
                    <breadcrumb />

                    <div class="card card-body">

                        <h5 class="font-weight-bold m-0">Programma terapeutico</h5>

                        <p class="text-muted mb-3">
                            <small>
                                Modifica il programma terapeutico per questa associazione tra paziente e patologia.
                            </small>
                        </p>

                        <div class="alert alert-info">
                            Per l'effettiva applicazione del programma terapeutico verrà effettuata una richiesta dopo la creazione di un appuntamento
                        </div>

                        <div class="row">
                            <div class="col-xl-3 col-lg-6">
                                <form @submit.prevent="update_therapy_program" autocomplete="off">
                                
                                    <div class="form-group">

                                        <label for="quantity">Quantità di visite</label>
                                        <input type="number" min="1" class="form-control" id="quantity" v-model.number="$v.form_therapy_program.quantity.$model" v-bind:class="{'is-invalid': (!$v.form_therapy_program.quantity.required || !$v.form_therapy_program.quantity.minValue) && $v.form_therapy_program.quantity.$dirty}">
                                        
                                        <small class="form-text text-muted">
                                            Seleziona la quantità di appuntamenti che verranno creati (min. 1)
                                        </small>
                                        <div class="invalid-feedback" v-show="!$v.form_therapy_program.quantity.required && $v.form_therapy_program.quantity.$dirty">
                                            E' necessario impostare la quantità di visite
                                        </div>
                                        <div class="invalid-feedback" v-show="!$v.form_therapy_program.quantity.minValue && $v.form_therapy_program.quantity.$dirty">
                                            E' necessario impostare almeno una visita
                                        </div>

                                    </div>
                                    <div class="form-group">

                                        <label for="frequency">Frequenza settimanale</label>
                                        <input type="number" min="1" class="form-control" id="frequency" v-model.number="$v.form_therapy_program.frequency.$model" v-bind:class="{'is-invalid': (!$v.form_therapy_program.frequency.required || !$v.form_therapy_program.frequency.minValue) && $v.form_therapy_program.frequency.$dirty}">
                                        
                                        <small class="form-text text-muted">
                                            Seleziona ogni quante <b>settimane</b> si deve ripetere l'appuntamento (min. 1)
                                        </small>
                                        <div class="invalid-feedback" v-show="!$v.form_therapy_program.frequency.required && $v.form_therapy_program.frequency.$dirty">
                                            E' necessario impostare la frequenza settimanale
                                        </div>
                                        <div class="invalid-feedback" v-show="!$v.form_therapy_program.frequency.minValue && $v.form_therapy_program.frequency.$dirty">
                                            E' necessario impostare almeno ogni 1 settimana
                                        </div>                                    

                                    </div>
                                    <div class="alert alert-danger" v-show="validation.ko">
                                        {{validation.ko}}
                                    </div>

                                    <button v-if="!functionDisabled(11, 'update')" class="btn btn-ifo mb-3" :disabled="loaders.updating || $v.form_therapy_program.$invalid">
                                    
                                        <span v-show="!loaders.updating">
                                            Modifica
                                        </span>

                                        <div v-show="loaders.updating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>

                                </form>
                            </div>
                        </div>

                    </div>
                    
                </div>

            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a v-b-toggle.elimina v-bind:class="{'disabled': loaders.deleting || functionDisabled(11, 'delete')}" class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom">
                            Elimina <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash ml-2 mt-1" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </a>
                        <b-collapse id="elimina">
                            <div class="alert alert-danger m-0">
                                Sicuro di voler eliminare questo programma?
                            </div>
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <button @click="delete_therapy_program" class="btn btn-ifo btn-block btn-sm rounded-0" :disabled="loaders.deleting">
                                    
                                        <span v-show="!loaders.deleting">
                                            Si
                                        </span>

                                        <div v-show="loaders.deleting" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>
                                </div>
                                <div class="col-6 pl-0">
                                    <button v-b-toggle.elimina class="btn btn-ifo btn-block btn-sm rounded-0">No</button>
                                </div>
                            </div>
                        </b-collapse>
                    </li>
                </ul>
            </div>

        </div>  
    </div>
</template>

<script>

    import { 
        required,
        minValue
    } from "vuelidate/lib/validators"

    import store  from "@/store"

    export default {
        data() {
            return {
                form_therapy_program: {
                    quantity:  0,
                    frequency: 0,
                    id_patient_pathology: this.$route.params.id_patient_pathology
                },
                loaders: {
                    reading:  true,
                    updating: false,
                    deleting: false
                },
                validation: {
                    ko: null
                }
            }
        },
        validations() {
            return {
                form_therapy_program: {
                    quantity: {
                        required,
                        minValue: minValue(1)
                    },
                    frequency: {
                        required,
                        minValue: minValue(1)
                    }
                }
            }
        },
        methods: {
            functionDisabled(id_function, action) {
                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                }
            },
            async update_therapy_program() {
                
                this.loaders.updating = true
                this.validation.ko    = false

                try {
                    const res = await this.axios.post(`user/therapy_program/update`, this.form_therapy_program, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })

                    this.$router.push(`/user/pazienti/modifica/${this.$route.params.CF}/associazione/patologie/modifica/${this.$route.params.id}`)

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: 'error'
                    })

                    this.validation.ko    = message
                    this.loaders.updating = false

                }
                
            },
            async read_therapy_program() {

                const {
                    id
                } = this.$route.params

                try {

                    this.form_therapy_program = (
                        await this.axios.get(`user/therapy_program/read/detail/${id}`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                }
                catch(e) {

                    this.$toast.open({
                        message: `Si è verificato un problema, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}` ,
                        type:    `error`
                    })

                    this.$router.go(-1)

                }
                finally {
                    this.loaders.reading = false
                }

            },
            async delete_therapy_program() {

                this.loaders.deleting = true

                try {

                    const res = await this.axios.delete(`user/therapy_program/${this.$route.params.id}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   `success`
                    })

                    this.$router.push(`/user/pazienti/modifica/${this.$route.params.CF}/associazione/patologie/modifica/${this.$route.params.id}`)
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   'error'
                    })

                    this.loaders.deleting = false

                }   

            }
        },
        async mounted() {

            this.read_therapy_program()
            
        }
    }

</script>