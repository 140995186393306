<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />

                <div class="card card-body">

                    <h5 class="font-weight-bold m-0">Aggiungi una nuova tipologia di utenza</h5>

                    <p class="text-muted mb-3">
                        <small>
                            In questa schermata puoi creare una nuova tipologia di utenza con correlate autorizzazioni per l'utilizzo del software
                        </small>
                    </p>

                    <form @submit.prevent="createUserType" autocomplete="off">

                        <div class="row">
                            <div class="col-lg-5">
                                <div class="form-group">

                                    <label for="title">Titolo</label>

                                    <input type="text" class="form-control" id="title" v-model.trim="$v.form.user_type.title.$model" v-bind:class="{'is-invalid': !$v.form.user_type.title.required && $v.form.user_type.title.$dirty}">
                                    
                                    <p v-if="!$v.form.user_type.title.$dirty" class="m-0 text-muted">
                                        <small>Il titolo dovrà essere univoco</small>
                                    </p>
                                    <p v-if="!$v.form.user_type.title.required && $v.form.user_type.title.$dirty" class="m-0 text-danger">
                                        <small>Titolo richiesto</small>
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5">
                                <div class="form-group">

                                    <label for="description">Descrizione</label>

                                    <textarea class="form-control" id="description" v-model.trim="$v.form.user_type.description.$model" v-bind:class="{'is-invalid': !$v.form.user_type.description.required && $v.form.user_type.description.$dirty}"></textarea>
                                    
                                    <p v-if="!$v.form.user_type.description.required && $v.form.user_type.description.$dirty" class="m-0 text-danger">
                                        <small>Descrizione richiesta</small>
                                    </p>

                                </div>
                            </div>
                        </div>

                        <button class="btn btn-ifo" :disabled="$v.$invalid">Aggiungi</button>

                    </form>

                </div>
            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a href class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom disabled">
                            <i class="text-muted">
                                Nessuna funzione
                            </i>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>

    import { 
        required
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                form: {

                    user_type: {
                        title:       ``,
                        description: ``
                    }

                }
            }
        },
        validations() {
            return {
                form: {
                    user_type: {
                        title: {
                            required
                        },
                        description: {
                            required
                        }
                    }
                }
            }
        },
        methods: {
            async createUserType() {
                try {
                    const res = await this.axios.post(`user/user_types/create`, this.form, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.$toast.open({
                        message: res.data.msg,
                        type:   `success`
                    })
                    this.$router.push(`/user/utenti/tipologie`)
                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko    = message
                    this.loaders.creating = false

                }

            },
            
        }
    }
</script>