<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />

                <!-- Filtri di ricerca-->
                <div class="card card-body mb-2">

                    <p class="font-weight-bold m-0">Filtri di ricerca</p>

                    <p class="text-muted mb-3">
                        <small>
                            Compila i campi che seguono, la ricerca avverrà in automatico
                        </small>
                    </p>

                    <div class="row">
                        <div class="col-lg-2">
                            <div class="form-group">

                                <label for="IP">Indirizzo IP</label>
                                <input id="IP" class="form-control text-uppercase mb-1 mb-lg-0" type="text" @input="read_logs_filter" v-model="form.search.IP" v-bind:class="{'is-valid': validation.ok_search_log}">
                                
                                <div class="valid-feedback">
                                    {{validation.ok_search_log}}
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="form-group">
                                <label for="username">Username</label>
                                <input id="username" class="form-control text-lowercase mb-1 mb-lg-0" type="text" @input="read_logs_filter" v-model="form.search.username" v-bind:class="{'is-valid': validation.ok_search_log}">
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="form-group">
                                <label for="dalla_data">Dalla data</label>
                                <input id="dalla_data" type="date" class="form-control" @input="read_logs_filter" v-model="form.search.date" v-bind:class="{'is-valid': validation.ok_search_log}" required>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="form-group">
                                <label for="alla_data">Alla data</label>
                                <input id="alla_data" type="date" class="form-control" @input="read_logs_filter" v-model="form.search.date_to" v-bind:class="{'is-valid': validation.ok_search_log}" required>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label for="description">Azione</label>
                                <input id="description" type="text" class="form-control" @input="read_logs_filter" v-model="form.search.description" v-bind:class="{'is-valid': validation.ok_search_log}" required>
                            </div>
                        </div>
                    </div>
                </div>

                <loader v-if="loaders.reading_logs" />

                <div class="card card-body" v-if="validation.ko_search_log">
                    <div class="alert alert-danger m-0">
                        {{validation.ko_search_log}}
                    </div>
                </div>
                
                <div v-else>

                    <div class="card card-body">
                        <div class="container border rounded">
                            <div class="row">
                                <div class="col-md-2">
                                    <p class="m-0 p-1">IP</p>
                                </div>
                                <div class="col-md-2">
                                    <p class="m-0 p-1">Data</p>
                                </div>
                                <div class="col-md-2">
                                    <p class="m-0 p-1">Utente</p>
                                </div>
                                <div class="col-md-3">
                                    <p class="m-0 p-1">Azione</p>
                                </div>
                            </div>
                            <div v-for="log of logs.DB" :key="log.id" class="border-top row hover cursor-pointer">
                                <div class="col-md-2 text-monospace text-uppercase">
                                    <p class="m-0 p-1 font-weight-bold text-truncate">
                                        {{log.IPv4}}
                                    </p>
                                </div>
                                <div class="col-md-2">
                                    <p class="m-0 p-1 text-truncate">
                                        {{log.date | data_slash}} {{log.date | data_ora}}
                                    </p>
                                </div>
                                <div class="col-md-2">
                                    <p class="m-0 p-1 text-truncate">
                                        {{log.user.username}}
                                    </p>
                                </div>
                                <div class="col-md-6">
                                    <p class="m-0 p-1 text-truncate">
                                        {{log.description}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="logs.DB.length >= 30" class="card card-body p-2 mt-2">
                        <button class="btn btn-ifo m-auto" @click="moreResults">Carica altri log</button>
                    </div>

                </div>
                    
            </div>
            <div class="col-2 col-md-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column d-none d-md-block">
                    <li class="nav-item">
                        <export-excel class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom cursor-pointer" :data="data_for_excel" :fields="excel.fields" :worksheet="logs" :name="`logs${new Date() | data_underscore}`">
                            <span class="d-none d-md-block">excel</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-excel ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                            </svg>
                        </export-excel>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import Loader from "@/components/loader.vue"
    import moment from "moment"

    export default {
        data() {
            return {
                logs: {
                    DB: []
                },
                loaders: {
                    reading: true,
                    reading_logs: false
                },
                form: {
                    search: {
                        IP:          "",
                        date:        moment().format("YYYY-MM-DD"),
                        username:    "",
                        date_to:     moment().format("YYYY-MM-DD"),
                        description: ""
                    }
                },
                pagination: {
                    skip: 0,
                    take: 30
                },
                validation: {
                    ko_search_log: false,
                    ok_search_log: false
                },
                excel: {
                    fields: {
                        IP: "IPv4",
                        DATA: "date",
                        AZIONE: "description",
                        UTENTE: "username"
                    }
                }
            };
        },
        methods: {
            /*async readLogs() {

                const where = {
                    date: {
                        gte: moment(this.form.search.date).format("YYYY-MM-DDT00:00:00.000Z"),
                        lte: moment(this.form.search.date_to).format("YYYY-MM-DDT23:59:00.000Z")
                    }
                }

                const orderBy = {
                    date: "desc"
                }

                const {
                    skip,
                    take
                } = this.pagination

                try {
                    this.logs.DB = (
                        await this.axios.post(`user/logs/read/search`, {where, orderBy, skip, take}, {
                            headers: {
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data
                }
                catch (e) {
                    this.$toast.open({
                        message: e.response.data.msg,
                        type: `error`
                    });
                }
                finally {
                    this.loaders.reading = false;
                }

            },*/
            async read_logs_filter() {

                const {
                    IP,
                    date,
                    username,
                    date_to,
                    description
                } = this.form.search

                this.validation.ko_search_log = false
                this.validation.ok_search_log = false
                this.loaders.reading_logs     = true

                try {

                    this.logs.DB = (

                        await this.axios.post(

                            "user/logs/read/search_custom",

                            {
                                IP,
                                date,
                                username,
                                date_to,
                                description
                            },
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }

                        )

                    ).data

                    this.validation.ok_search_log = `Trovati ${this.logs.DB.length} logs`

                }
                catch(e) {

                    const {
                        message
                    } = e.response.data

                    this.$toast.open({
                        message,
                        type: "error"
                    })

                    this.validation.ko_search_log = message
                    this.logs.DB                  = []

                }
                finally {
                    this.loaders.reading_logs = false
                }                

            },
            moreResults() {
                this.pagination.take += 30
                this.read_logs_filter()
            }
        },
        mounted() {
            this.read_logs_filter()
        },
        computed: {
            data_for_excel() {
                return this.logs.DB.map(log => {
                    return {
                        IPv4: log.IPv4,
                        date: moment(log.date).format("DD/MM/YYYY"),
                        description: log.description,
                        username: log.user.username
                    }
                })
            }
        },
        components: {
            Loader
        }
    }
</script>