import Vue          from 'vue'
import Vuex         from 'vuex'
import App          from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import router       from './router'
import store        from './store'
import axios        from 'axios'
import VueAxios     from 'vue-axios'
import Vuelidate    from 'vuelidate'
import VueToast     from 'vue-toast-notification'
import excel        from 'vue-excel-export'
import VueSimpleAlert from "vue-simple-alert"; 
import { BTooltip } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './css/main.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import './filters/date'
import './filters/relations'
import 'vue-cal/dist/vuecal.css'

import loader         from './components/loader.vue'
import breadcrumb     from './components/breadcrumb.vue'

Vue.use(
  BootstrapVue
)

Vue.use(
  VueAxios,
  axios,
  Vuex
)

Vue.use(
  Vuelidate
)

Vue.use(
  VueSimpleAlert
)

Vue.use(
  VueToast, {
    position: "top-right"
  }
)

Vue.use(
  excel
)

// Impostiamo componenti come globali
Vue.component('loader', loader)
Vue.component('breadcrumb', breadcrumb)
Vue.component('b-tooltip', BTooltip)

Vue.config.productionTip = false

const URL = {
  test: `http://localhost:5001`,
  prod: `https://oncogadh2.kgpartners.it`
}

// Configurazioni axios
axios.interceptors.response.use(

  function(successRes) {

    return successRes

  },
  async function(error) {
    
    // Se errore 401 redirect in sessione scaduta
    if(error.response.status == 401) {
      
      Vue.$toast.open({
        message: "Sessione scaduta, è necessario effettuare nuovamente il login",
        type:    "error"
      })

      await store.dispatch('doCentralLogout')
      router.push("/")

    }
    return Promise.reject(error);

  }

)

axios.defaults.withCredentials  = true 
axios.defaults.baseURL          = `${URL.prod}/api/`

axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
}

new Vue({
  store,
  router,
  components: {
    loader,
    breadcrumb
  },
  render: h => h(App)
}).$mount('#app')