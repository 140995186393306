<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />

                <div class="card card-body">

                    <h5 class="font-weight-bold m-0">Programma terapeutico</h5>

                    <p class="text-muted mb-3">
                        <small>
                            Crea un programma terapeutico per questa associazione tra paziente e patologia.
                        </small>
                    </p>

                    <div class="alert alert-info">
                        Per l'effettiva applicazione del programma terapeutico verrà effettuata una richiesta dopo la creazione di un appuntamento
                    </div>

                    <div class="row">
                        <div class="col-xl-3 col-lg-6">
                            <form @submit.prevent="creating_therapy_program" autocomplete="off">
                            
                                <div class="form-group">

                                    <label for="quantity">Quantità di visite</label>
                                    <input type="number" min="1" class="form-control" id="quantity" v-model.number="$v.form_therapy_program.quantity.$model" v-bind:class="{'is-invalid': (!$v.form_therapy_program.quantity.required || !$v.form_therapy_program.quantity.minValue) && $v.form_therapy_program.quantity.$dirty}">
                                    
                                    <small class="form-text text-muted">
                                        Seleziona la quantità di appuntamenti che verranno creati (min. 1)
                                    </small>
                                    <div class="invalid-feedback" v-show="!$v.form_therapy_program.quantity.required && $v.form_therapy_program.quantity.$dirty">
                                        E' necessario impostare la quantità di visite
                                    </div>
                                    <div class="invalid-feedback" v-show="!$v.form_therapy_program.quantity.minValue && $v.form_therapy_program.quantity.$dirty">
                                        E' necessario impostare almeno una visita
                                    </div>

                                </div>
                                <div class="form-group">

                                    <label for="frequency">Frequenza settimanale</label>
                                    <input type="number" min="1" class="form-control" id="frequency" v-model.number="$v.form_therapy_program.frequency.$model" v-bind:class="{'is-invalid': (!$v.form_therapy_program.frequency.required || !$v.form_therapy_program.frequency.minValue) && $v.form_therapy_program.frequency.$dirty}">
                                    
                                    <small class="form-text text-muted">
                                        Seleziona ogni quante <b>settimane</b> si deve ripetere l'appuntamento (min. 1)
                                    </small>
                                    <div class="invalid-feedback" v-show="!$v.form_therapy_program.frequency.required && $v.form_therapy_program.frequency.$dirty">
                                        E' necessario impostare la frequenza settimanale
                                    </div>
                                    <div class="invalid-feedback" v-show="!$v.form_therapy_program.frequency.minValue && $v.form_therapy_program.frequency.$dirty">
                                        E' necessario impostare almeno ogni 1 settimana
                                    </div>                                    

                                </div>
                                <div class="alert alert-danger" v-show="validation.ko">
                                    {{validation.ko}}
                                </div>

                                <button v-if="!functionDisabled(11, 'create')" class="btn btn-ifo mb-3" :disabled="loaders.creating || $v.form_therapy_program.$invalid">
                                
                                    <span v-show="!loaders.creating">
                                        Aggiungi
                                    </span>

                                    <div v-show="loaders.creating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                </button>

                            </form>
                        </div>
                    </div>

                </div>

            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a href class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom disabled">
                            <i class="text-muted">
                                Nessuna funzione
                            </i>
                        </a>
                    </li>
                </ul>
            </div>

        </div>  
    </div>
</template>

<script>

    import { 
        required,
        minValue
    } from "vuelidate/lib/validators"

    import store  from "@/store"

    export default {
        data() {
            return {
                form_therapy_program: {
                    quantity:  0,
                    frequency: 0,
                    id_patient_pathology: this.$route.params.id
                },
                loaders: {
                    creating:   false,
                    deleting: false
                },
                validation: {
                    ko: null
                }
            }
        },
        validations() {
            return {
                form_therapy_program: {
                    quantity: {
                        required,
                        minValue: minValue(1)
                    },
                    frequency: {
                        required,
                        minValue: minValue(1)
                    }
                }
            }
        },
        methods: {
            functionDisabled(id_function, action) {
                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                }
            },
            async creating_therapy_program() {
                
                this.loaders.creating = true
                this.validation.ko    = false

                try {
                    const res = await this.axios.post(`user/therapy_program/create`, this.form_therapy_program, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })

                    this.$router.push(`/user/pazienti/modifica/${this.$route.params.CF}/associazione/patologie/modifica/${this.$route.params.id}`)

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: 'error'
                    })

                    this.validation.ko    = message
                    this.loaders.creating = false

                }
                
            }
        },
        async mounted() {
            
        }
    }

</script>