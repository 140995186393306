<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />

                <loader v-if="loaders.reading"/>
                    
                <div class="card card-body mb-2" v-else>

                    <h5 class="font-weight-bold m-0">Modifica la patologia</h5>

                    <p class="text-muted mb-3">
                        <small>
                            Informazioni sulla patologia e sulle sue terapie associate
                        </small>
                    </p>

                    <form @submit.prevent="updatePhatology" autocomplete="off">

                        <div class="row">
                            <div class="col-lg-1">
                                <div class="form-group">
                                    <label for="id_pathology">Identificativo</label>
                                    <input type="text" class="form-control text-monospace" id="id_pathology" :value="form.id_pathology" disabled>
                                </div>
                            </div>
                            <div class="col-lg-3">

                                <div class="form-group">
                                    <label for="title">Titolo</label>
                                    <input type="text" class="form-control" id="title" :value="form.title" disabled>
                                    <small class="form-text text-muted">Il titolo della patologia</small>
                                </div>

                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="pathology_type_title">Tipologia patologia</label>
                                    <input type="text" class="form-control" id="pathology_type_title" :value="form.pathology_type.title" disabled>                           
                                    <small class="form-text text-muted">Ogni patologia appartiene ad uno specifica tipologia</small>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="pathology_group_title">Gruppo patologia</label>
                                    <input type="text" class="form-control" id="pathology_group_title" :value="form.pathology_type.pathology_group.title" disabled>                           
                                    <small class="form-text text-muted">Ogni patologia appartiene ad uno specifico gruppo</small>
                                </div>
                            </div>
                        </div>

                        <div v-show="validation.ko" class="alert alert-danger">
                            {{validation.ko}}
                        </div>
                    
                    </form>

                    <b-tabs content-class="border border-top-0">
                        <b-tab title="Terapie associate" active>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-10 p-3">

                                        <div v-if="form.pathologies_therapies.length < 1" class="alert alert-warning m-0">
                                            Ancora nessuna terapia associata
                                        </div>

                                        <div class="table-responsive" v-else>
                                            <table class="table table-bordered table-hover table-sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            Terapia
                                                        </th>
                                                        <th scope="col">
                                                            Descrizione
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <router-link tag="tr" v-for="pathology_teraphy of form.pathologies_therapies" :key="pathology_teraphy.id" :to="`/user/patologie/modifica/${$route.params.title}/associazione/terapie/modifica/${pathology_teraphy.id}`" class="hover cursor-pointer" v-bind:class="{'disabled_invisible': functionDisabled(7, 'update')}">
                                                        <td>
                                                            {{pathology_teraphy.therapy.title}}
                                                        </td>
                                                        <td>
                                                            {{pathology_teraphy.therapy.description}}
                                                        </td>
                                                    </router-link>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div class="col-lg-2 p-0 border-left">
                                        <ul class="nav flex-column">
                                            <li class="nav-item">
                                                <router-link :to="`/user/patologie/modifica/${this.$route.params.title}/associazione/terapie/aggiungi`" class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom" v-bind:class="{'disabled': functionDisabled(7, 'update')}">
                                                    Associa <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle ml-2" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                    </svg>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                
                </div>

            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a v-b-toggle.elimina v-bind:class="{'disabled': loaders.deleting || functionDisabled(7, 'delete')}" class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom disabled">
                            Elimina <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash ml-2 mt-1" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </a>
                        <b-collapse id="elimina">
                            <div class="alert alert-danger m-0">
                                Sicuro di voler eliminare questa patologia?
                            </div>
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <button @click="deletePathology" class="btn btn-ifo btn-block btn-sm rounded-0" :disabled="loaders.deleting">
                                    
                                        <span v-show="!loaders.deleting">
                                            Si
                                        </span>

                                        <div v-show="loaders.deleting" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>
                                </div>
                                <div class="col-6 pl-0">
                                    <button v-b-toggle.elimina class="btn btn-ifo btn-block btn-sm rounded-0">No</button>
                                </div>
                            </div>
                        </b-collapse>
                    </li>
                    <li class="nav-item">
                        <a @click="suspendPathology" class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom" v-bind:class="{'disabled': loaders.suspending || functionDisabled(7, 'update')}">
                            <span v-show="!loaders.suspending && form.state">
                                Non visibile <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause-circle ml-2" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                </svg>
                            </span>
                            <span v-show="!loaders.suspending && !form.state">
                                Visibile <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle ml-2" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                </svg>
                            </span>

                            <div v-show="loaders.suspending" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required
    } from "vuelidate/lib/validators"

    import store  from '@/store'

    export default {
        data() {
            return {
                form: {
                    title:                 null,
                    //pathologies_therapies: []
                },
                validation: {
                    ko: false
                },
                loaders: {
                    reading:    true,
                    creating:   false,
                    updating:   false,
                    deleting:   false,
                    suspending: false
                },
                DB: {
                    therapies: []
                }
            }
        },
        validations: {
            form: {
                title: {
                    required
                }
            }
        },
        methods: {
            async updatePathology() {

                this.loaders.updating = true

                try {

                    const patologia = await this.axios.post(`user/pathologies/update`, this.form,
                    {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.$toast.open({
                        message: patologia.data.msg,
                        type:   `success`
                    })
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.updating = false
                }
                
                
            },
            async readPathology() {

                try {

                    const pathology = await this.axios.get(`user/pathologies/read/detail/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form = pathology.data
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                    this.$router.go(-1)

                }
                finally {
                    this.loaders.reading = false
                }

            },
            async deletePathology() {

                this.loaders.deleting = true

                try {

                    const res = await this.axios.delete(`user/pathologies/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })

                    this.$router.push(`/user/patologie`)
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                    this.loaders.deleting = false

                }

            },
            async suspendPathology() {

                this.loaders.suspending = true

                try {

                    const res = await this.axios.get(`user/pathologies/suspend/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   `success`
                    })

                    this.form.state = !this.form.state
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.suspending = false
                }

            },
            /*async deletePathologyTherapy(pathology_therapy, index) {

                try {

                    // Eliminiamo record dalla tabella relazionale
                    const res = await this.axios.delete(`user/pathologies_therapies/${pathology_therapy.id}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    // Spostiamo oggetto nell'array delle terapie disponibili
                    this.DB.therapies.push(pathology_therapy.therapy)
                    // Eliminiamo oggetto dall'array delle terapie associate
                    this.form.pathologies_therapies.splice(index, 1)

                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },
            async readTherapies() {

                let where = {
                    NOT: [

                    ]
                }

                for(let pathology_teraphy of this.form.pathologies_therapies) {

                    const {
                        id
                    } = pathology_teraphy.therapy

                    where.NOT.push({
                        id
                    })

                }

                const therapies = await this.axios.post(`user/therapies/read/search`, {where}, {
                    headers:{
                        Authorization: `bearer ${this.$store.state.auth.token}`
                    }
                })
                
                this.DB.therapies = therapies.data

            },
            async createPathologyTherapy(therapy, index) {

                let data = {
                    id_pathology: this.form.id,
                    id_therapy:   therapy.id
                }

                try {

                    const res = await this.axios.post(`user/pathologies_therapies/create`, data, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    data['therapy'] = therapy
                    data['id']      = res.data.pathology_therapy.id

                    // Spostiamo oggetto nell'array delle terapie associate
                    this.form.pathologies_therapies.push(data)
                    // Eliminiamo oggetto dall'array delle terapie disponibili
                    this.DB.therapies.splice(index, 1)

                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },*/
            functionDisabled(id_function, action) {
                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                }
            }
        },
        async mounted() {
            await this.readPathology()
        }
    }

</script>