<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <loader v-if="loaders.reading" />

                <div v-else>

                    <!-- Breadcrumb -->
                    <breadcrumb />

                    <div class="card card-body mb-3">

                        <h5 class="font-weight-bold m-0">Modifica terapia</h5>

                        <p class="text-muted mb-3">
                            <small>
                                Modifica la informazioni sulla terapia selezionata
                            </small>
                        </p>

                        <form @submit.prevent="updateTherapy" autocomplete="off">

                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="title">Titolo</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control text-uppercase" id="title" v-model.trim="$v.form.title.$model" v-bind:class="{'is-invalid': !$v.form.title.required && $v.form.title.$dirty}" required disabled>                           
                                        </div>
                                        <p v-if="!$v.form.title.required && $v.form.title.$dirty" class="m-0 text-danger">
                                            <small>
                                                Titolo richiesto
                                            </small>
                                        </p>
                                    </div>
                                    <div class="form-group">
                                        <label for="description">Descrizione</label>
                                        <div class="input-group">
                                            <textarea class="form-control" id="description" v-model.trim="$v.form.description.$model"></textarea>                           
                                        </div>
                                    </div>

                                    <div v-show="validation.ko" class="alert alert-danger">
                                        {{validation.ko}}
                                    </div>

                                    <button v-if="!functionDisabled(3, 'update')" @click="updateTherapy" class="btn btn-ifo" :disabled="$v.$invalid || !$v.$anyDirty || loaders.updating">
                                            
                                        <span v-show="!loaders.updating">
                                            Modifica
                                        </span>

                                        <div v-show="loaders.updating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>
                                </div>
                            </div>

                        </form>

                    </div>

                    <b-tabs content-class="border border-top-0">
                        <b-tab title="Patologie associate" active>
                            <div class="container-fluid p-3">

                                <div v-show="pathologies_therapies.length < 1" class="alert alert-warning m-0">
                                    Ancora nessuna patologia associata
                                </div>

                                <div class="row">
                                    <div v-for="pathologies_therapy of pathologies_therapies" :key="pathologies_therapy.id" div class="col-lg-4">
                                        <div class="card card-body mb-2">
                                            <div class="row">
                                                <div class="col-7">
                                                    <p class="text-uppercase font-weight-bold">
                                                        {{pathologies_therapy.pathology.title}}
                                                    </p>
                                                </div>
                                                <div class="col-5 text-right d-flex flex-column justify-content-between">
                                                    <router-link tag="button" :to="`/user/patologie/modifica/${pathologies_therapy.pathology.title}`" class="btn btn-ifo btn-sm" :disabled="functionDisabled(7, 'read')">
                                                        Gestisci
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </b-tab>
                    </b-tabs>
                    
                </div>

            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a v-b-toggle.elimina v-bind:class="{'disabled': loaders.deleting || functionDisabled(3, 'delete')}" class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom">
                            Elimina <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash ml-2 mt-1" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </a>
                        <b-collapse id="elimina">
                            <div class="alert alert-danger m-0">
                                Sicuro di voler eliminare questa patologia?
                            </div>
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <button @click="deleteTherapy" class="btn btn-ifo btn-block btn-sm rounded-0" :disabled="loaders.deleting">
                                    
                                        <span v-show="!loaders.deleting">
                                            Si
                                        </span>

                                        <div v-show="loaders.deleting" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>
                                </div>
                                <div class="col-6 pl-0">
                                    <button v-b-toggle.elimina class="btn btn-ifo btn-block btn-sm rounded-0">No</button>
                                </div>
                            </div>
                        </b-collapse>
                    </li>
                    <li class="nav-item">
                        <a @click="suspendTherapy" v-bind:class="{'disabled': loaders.suspending || functionDisabled(3, 'update')}" class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom">
                            <span v-show="!loaders.suspending && form.state">
                                Non visibile <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause-circle ml-2" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                </svg>
                            </span>
                            <span v-show="!loaders.suspending && !form.state">
                                Visibile <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle ml-2" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                </svg>
                            </span>

                            <div v-show="loaders.suspending" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required
    } from "vuelidate/lib/validators"

    import store  from '@/store'

    export default {
        data() {
            return {
                form: {
                    title:       null,
                    description: null
                },
                pathologies_therapies: [

                ],
                validation: {
                    ko: false
                },
                loaders: {
                    reading:    true,
                    creating:   false,
                    updating:   false,
                    deleting:   false,
                    suspending: false
                },
            }
        },
        validations: {
            form: {
                title: {
                    required
                },
                description: {

                }
            }
        },
        methods: {
            async updateTherapy() {

                this.loaders.updating = true

                try {

                    const terapia = await this.axios.post(`user/therapies/update`, {
                        title:       this.$route.params.title,
                        description: this.form.description
                    },
                    {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: terapia.data.msg,
                        type:   `success`
                    })
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.updating = false
                }
                
                
            },
            async readTherapy() {

                try {

                    const therapy = await this.axios.get(`user/therapies/read/detail/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form                  = therapy.data
                    this.pathologies_therapies = therapy.data.pathologies_therapies
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                    this.$router.go(-1)

                }
                finally {
                    this.loaders.reading = false
                }

            },
            async deleteTherapy() {

                this.loaders.deleting = true

                try {

                    const res = await this.axios.delete(`user/therapies/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   `success`
                    })

                    this.$router.push(`/user/terapie`)
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                    this.loaders.deleting = false

                }

            },
            async suspendTherapy() {

                this.loaders.suspending = true

                try {

                    const res = await this.axios.get(`user/therapies/suspend/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   `success`
                    })

                    this.form.state = !this.form.state
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.suspending = false
                }

            },
            functionDisabled(id_function, action) {
                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                }
            }
        },
        mounted() {
            this.readTherapy()
        }
    }

</script>