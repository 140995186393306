<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <loader v-if="loaders.reading" />

                <div v-else>

                    <!-- Breadcrumb -->
                    <breadcrumb />

                    <div class="card card-body">

                        <form @submit.prevent="update_user_type" autocomplete="off" class="mb-3">

                            <div class="row">
                                <div class="col-lg-5">
                                    <div class="form-group">

                                        <label for="title">Titolo</label>
                                        <input type="text" class="form-control" id="title" :value="form.user_type.title" disabled>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-5">
                                    <div class="form-group">

                                        <label for="description">Descrizione</label>

                                        <textarea class="form-control" id="description" v-model.trim="$v.form.user_type.description.$model" v-bind:class="{'is-invalid': !$v.form.user_type.description.required && $v.form.user_type.description.$dirty}"></textarea>
                                        
                                        <p v-if="!$v.form.user_type.description.required && $v.form.user_type.description.$dirty" class="m-0 text-danger">
                                            <small>Descrizione richiesta</small>
                                        </p>

                                    </div>
                                </div>
                            </div>

                            <button class="btn btn-ifo" :disabled="$v.$invalid">Modifica</button>

                        </form>

                        <b-tabs content-class="border border-top-0">
                            <b-tab title="Abilitazioni software" active>
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-lg-10 p-3">

                                            <div v-if="form.user_type.users_software_functions.length < 1" class="alert alert-warning m-0">
                                                Ancora nessuna funzionalità associata
                                            </div>

                                            <div class="table-responsive m-0" v-else>
                                                <table class="table table-bordered table-hover table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                Funzione
                                                            </th>
                                                            <th scope="col">
                                                                Descrizione
                                                            </th>
                                                            <th scope="col">
                                                                Creazione
                                                            </th>
                                                            <th scope="col">
                                                                Lettura
                                                            </th>
                                                            <th scope="col">
                                                                Modifica
                                                            </th>
                                                            <th scope="col">
                                                                Eliminazione
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <router-link tag="tr" :to="`/user/utenti/tipologie/modifica/${$route.params.title}/autorizzazioni/modifica/${user_software_function.software_function.title}`" v-for="user_software_function of form.user_type.users_software_functions" :key="`software_function_${user_software_function.id}`">
                                                            <td>
                                                                {{user_software_function.software_function.title}}
                                                            </td>
                                                            <td>
                                                                {{user_software_function.software_function.description}}
                                                            </td>
                                                            <td class="text-center font-weight-bold" v-bind:class="{'text-success': user_software_function.create, 'text-danger': !user_software_function.create}">
                                                                {{user_software_function.create ? `Si` : 'No'}}
                                                            </td>
                                                            <td class="text-center font-weight-bold" v-bind:class="{'text-success': user_software_function.read, 'text-danger': !user_software_function.read}">
                                                                {{user_software_function.read ? `Si` : 'No'}}
                                                            </td>
                                                            <td class="text-center font-weight-bold" v-bind:class="{'text-success': user_software_function.update, 'text-danger': !user_software_function.update}">
                                                                {{user_software_function.update ? `Si` : 'No'}}
                                                            </td>
                                                            <td class="text-center font-weight-bold" v-bind:class="{'text-success': user_software_function.delete, 'text-danger': !user_software_function.delete}">
                                                                {{user_software_function.delete ? `Si` : 'No'}}
                                                            </td>
                                                        </router-link>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <div class="col-lg-2 p-0 border-left">
                                            <ul class="nav flex-column">
                                                <li class="nav-item">
                                                    <router-link :to="`/user/utenti/tipologie/modifica/${$route.params.title}/autorizzazioni/aggiungi`" class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom">
                                                        Associa <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle ml-2" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                        </svg>
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab title="Abilitazioni speciali software">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-lg-10 p-3">

                                            <div v-if="form.user_type.user_types_software_special_functions.length < 1" class="alert alert-warning m-0">
                                                Ancora nessuna funzionalità speciale associata
                                            </div>

                                            <div class="table-responsive m-0" v-else>
                                                <table class="table table-bordered table-hover table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                Funzione
                                                            </th>
                                                            <th scope="col">
                                                                Descrizione
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <router-link tag="tr" :to="`/user/utenti/tipologie/modifica/${$route.params.title}/autorizzazioni_speciali/modifica/${user_type_software_special_function.software_special_function.title}`" v-for="user_type_software_special_function of form.user_type.user_types_software_special_functions" :key="`software_special_function_${user_type_software_special_function.id}`">
                                                            <td>
                                                                {{user_type_software_special_function.software_special_function.title}}
                                                            </td>
                                                            <td>
                                                                {{user_type_software_special_function.software_special_function.description}}
                                                            </td>
                                                        </router-link>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <div class="col-lg-2 p-0 border-left">
                                            <ul class="nav flex-column">
                                                <li class="nav-item">
                                                    <router-link :to="`/user/utenti/tipologie/modifica/${$route.params.title}/autorizzazioni_speciali/aggiungi`" class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom">
                                                        Associa <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle ml-2" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                        </svg>
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>

                    </div>

                </div>

            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a v-b-toggle.elimina class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom">
                            Elimina <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash ml-2 mt-1" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </a>
                        <b-collapse id="elimina">
                            <div class="alert alert-danger m-0">
                                Sicuro di voler eliminare questa tipologia di utenza?
                            </div>
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <button @click="delete_user_type" class="btn btn-ifo btn-block btn-sm rounded-0" :disabled="loaders.deleting">
                                    
                                        <span v-show="!loaders.deleting">
                                            Si
                                        </span>

                                        <div v-show="loaders.deleting" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>
                                </div>
                                <div class="col-6 pl-0">
                                    <button v-b-toggle.elimina class="btn btn-ifo btn-block btn-sm rounded-0">No</button>
                                </div>
                            </div>
                        </b-collapse>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>

    import { 
        required
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                form: {
                    user_type: {
                        title:                                 "",
                        description:                           "",
                        users_software_functions:              [],
                        user_types_software_special_functions: []
                    }
                },
                loaders: {
                    reading:  true,
                    updating: false,
                    deleting: false
                }
            }
        },
        validations() {
            return {
                form: {
                    user_type: {
                        description: {
                            required
                        }
                    }
                }
            }
        },
        methods: {
            async update_user_type() {

                this.loaders.updating = true

                try {

                    const res = await this.axios.post(`user/user_types/update`, this.form, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   `success`
                    })

                    this.$router.push(`/user/utenti/tipologie`)

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko    = message
                    this.loaders.updating = false

                }

            },
            async read_user_type() {
                try {

                    this.form.user_type = (
                        await this.axios.get(`user/user_types/read/detail/${this.$route.params.title}`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                }
                catch(e) {

                    this.$toast.open({
                        message: `Si è verificato un problema,${e.response ? ' ' + e.response.data.msg : ' verifica la tua connessione.'}` ,
                        type:    `error`
                    })

                }
                finally{
                    this.loaders.reading = false
                }
            },
            async delete_user_type() {
                
                this.loaders.deleting = true

                try {

                    const res = await this.axios.delete(`user/user_types/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   "success"
                    })

                    this.$router.push(`/user/utenti/tipologie`)
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   'error'
                    })

                    this.loaders.deleting = false

                }

            }
        },
        mounted() {
            this.read_user_type()
        }
    }

</script>