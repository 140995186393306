<template>
    <div class="container-fluid">

        <div class="row">

            <div class="col-12 col-md-10 py-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />

                <div class="card card-body">

                    <form @submit.prevent="create_pathology" autocomplete="off">

                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="id_pathology_type">Seleziona la tipologia</label>

                                    <select class="form-control" id="id_pathology_type" v-model.number="$v.form.pathology.id_pathology_type.$model">
                                        <option v-for="pathology_type of DB.pathology_types" :key="`pathology_type_${pathology_type.id}`" :value="pathology_type.id">
                                            {{pathology_type.title}}
                                        </option>
                                    </select>

                                    <small class="form-text text-muted">
                                        Seleziona la tipologia di patologia
                                    </small>

                                </div>
                                <div class="form-group">

                                    <label for="pathology_group">Gruppo tipologia</label>
                                    <input id="pathology_group" type="text" class="form-control" :value="!title_pathology_group ? 'Seleziona una tipologia' : title_pathology_group.pathology_group.title" disabled>

                                </div>
                                <div class="form-group">

                                    <label for="title">Titolo</label>
                                    <input type="text" class="form-control text-uppercase " id="title" v-model.trim="$v.form.pathology.title.$model" v-bind:class="{'is-invalid': !$v.form.pathology.title.required && $v.form.pathology.title.$dirty}" required>                           

                                    <div class="invalid-feedback" v-if="!$v.form.pathology.title.required && $v.form.pathology.title.$dirty">
                                        Titolo richiesto
                                    </div>
                                    <small class="form-text text-warning">
                                        Attenzione il titolo non potrà essere modificato
                                    </small>

                                </div>
                                <div class="form-group">

                                    <label for="id_pathology">Identificativo Patologia</label>
                                    <input type="text" class="form-control text-uppercase" id="id_pathology" minlength="3" maxlength="3" v-model.trim="$v.form.pathology.id_pathology.$model" v-bind:class="{'is-invalid': (!$v.form.pathology.id_pathology.required || !$v.form.pathology.id_pathology.minLength || !$v.form.pathology.id_pathology.maxLength || exist_id_pathology == 1) && $v.form.pathology.id_pathology.$dirty}" @input="read_id_pathology" required>                           

                                    <div class="invalid-feedback" v-if="!$v.form.pathology.id_pathology.required && $v.form.pathology.id_pathology.$dirty">
                                        Identificativo univoco richiesto
                                    </div>
                                    <div class="invalid-feedback" v-if="!$v.form.pathology.id_pathology.minLength && $v.form.pathology.id_pathology.$dirty">
                                        Lunghezza minima di 3 caratteri
                                    </div>
                                    <div class="invalid-feedback" v-if="!$v.form.pathology.id_pathology.maxLength && $v.form.pathology.id_pathology.$dirty">
                                        Lunghezza massima di 3 caratteri
                                    </div>
                                    <div class="invalid-feedback" v-if="exist_id_pathology == 1 && $v.form.pathology.id_pathology.$dirty">
                                        Identificativo presente
                                    </div>
                                    <small class="form-text text-warning" v-if="loaders.reading.id_pathology">
                                        Controllo identificativo in corso..
                                    </small>

                                </div>
                            </div>
                        </div>

                        <div v-show="validation.ko" class="alert alert-danger">
                            {{validation.ko}}
                        </div>
                        
                        <div>
                            <button class="btn btn-ifo" :disabled="$v.$invalid || loaders.creating || loaders.reading.id_pathology || exist_id_pathology == 1">
                                
                                <span v-show="!loaders.creating">
                                    Aggiungi
                                </span>

                                <div v-show="loaders.creating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                            </button>
                        </div>

                    </form>

                </div>
            </div>
            <div class="col-2 col-md-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a href class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom disabled">
                            <i class="text-muted">
                                Nessuna funzione
                            </i>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
        
    </div>
</template>

<script>

    import { 
        required,
        minLength,
        maxLength
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                DB: {
                    pathology_types: []
                },
                form: {
                    pathology: {
                        title: "",
                        id_pathology_type: null,
                        id_pathology: ""
                    }
                },
                validation: {
                    ko: false
                },
                loaders: {
                    creating: false,
                    reading: {
                        id_pathology: false
                    }
                },
                exist_id_pathology: 0
            }
        },
        validations: {
            form: {
                pathology: {
                    title: {
                        required
                    },
                    id_pathology_type: {
                        required
                    },
                    id_pathology: {
                        required,
                        minLength: minLength(3),
                        maxLength: maxLength(3)
                    }
                }
                
            }
        },
        methods: {
            async create_pathology() {

                
                this.loaders.creating = true
                this.validation.ko    = false

                try {
                    const res = await this.axios.post(`user/pathologies/create`, this.form.pathology, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })
                    this.$router.push(`/user/patologie`)
                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: 'error'
                    })

                    this.validation.ko    = message
                    this.loaders.creating = false

                }
                
            },
            async read_pathology_types() {

                try {

                    this.DB.pathology_types = (
                        await this.axios.get(`user/pathology_types/read/all`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }

            },
            async read_id_pathology() {

                if(this.form.pathology.id_pathology.length == 3) {

                    this.loaders.reading.id_pathology = true

                    const data_exist = (
                        await this.axios.post(`user/pathologies/read/id_pathology`, {id_pathology: this.form.pathology.id_pathology}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                    this.loaders.reading.id_pathology = false

                    this.exist_id_pathology = data_exist ? 1 : 2
                
                }
                else {
                    this.exist_id_pathology = 0
                }

            }
        },
        mounted() {
            this.read_pathology_types()
        },
        computed: {
            title_pathology_group() {

                return this.DB.pathology_types.find(
                    pathology_type => pathology_type.id == this.form.pathology.id_pathology_type
                )

            }
        }
    }
</script>