<template>
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">{{$router.currentRoute.path.slice(6)}}</li>
            </ol>
        </nav>
    </div>
</template>

<script>
    export default {

    }
</script>