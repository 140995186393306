<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />

                <div class="card card-body">

                    <h5 class="font-weight-bold">Associa una terapia alla patologia</h5>

                    <div class="row">
                        <div class="col-lg-4">
                            <form @submit.prevent="create_pathologies_therapies" autocomplete="off">

                                <div class="form-group">

                                    <label for="therapies">Seleziona una terapia</label>

                                    <select class="form-control" id="therapies" v-model="form.id_therapy">
                                        <option v-for="therapy of DB.therapies" :key="`therapy_${therapy.id}`" :value="therapy.id">
                                            {{therapy.title}}
                                        </option>
                                    </select>

                                </div>

                                <div v-show="validation.ko" class="alert alert-danger text-center">
                                    {{validation.ko}}
                                </div>

                                <button class="btn btn-ifo" :disabled="loaders.creating || !form.id_therapy">

                                    <span v-show="!loaders.creating">
                                        Associa
                                    </span>

                                    <div v-show="loaders.creating" class="spinner-border spinner-border-sm" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>

                                </button>

                            </form>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a href class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom disabled">
                            <i class="text-muted">
                                Nessuna funzione
                            </i>
                        </a>
                    </li>
                </ul>
            </div>

        </div>  
    </div>
</template>

<script>

    export default {
        data() {
            return {
                DB: {
                    therapies: []
                },
                form: {
                    title: this.$route.params.title,
                    id_therapy: null
                },
                validation: {
                    ko: false
                },
                loaders: {
                    creating: false,
                    reading:  true
                }
            }
        },
        methods: {
            async create_pathologies_therapies() {

                this.loaders.creating = true
                this.validation.ko    = false

                try {

                    const res = await this.axios.post(`user/pathologies_therapies/create`, this.form, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   `success`
                    })

                    this.$router.go(-1)

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko    = message
                    this.loaders.creating = false

                }
                
            },
            async read_pathologies_therapies() {

                try {

                    const therapies = await this.axios.get(`user/pathologies_therapies/read/fromPathology/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.therapies = therapies.data

                }
                catch(e) {

                    this.$toast.open({
                        message: `Si è verificato un problema, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}` ,
                        type:    `error`
                    })

                    this.$router.go(-1)

                }
                finally {
                    this.loaders.reading = false
                }

            }            
        },
        mounted() {
            this.read_pathologies_therapies()
        }
    }

</script>