<template>
    <div>

        <nav class="navbar navbar-expand-lg border-bottom justify-content-between pr-4 pl-4">

            <router-link class="navbar-brand w-100 w-lg-50" to="/">
                <img src="../assets/ifo_logo_3.png" class="w-100 w-md-50 w-lg-75">
            </router-link>

            <div class="text-right w-50 d-none d-lg-block">

                <ul v-if="$route.name != 'password_create' && $route.name != 'password_update'">
                    <b-nav-item-dropdown :text="username" class="ul_no_style p-child-0" right>
                        <b-dropdown-item href @click="logout" class="text-right">
                            Logout <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right ml-2" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
                                <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                            </svg>
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </ul>
                
                <div v-if="$route.name != 'password_create' && $route.name != 'password_update'" class="mt-neg-21">
                    <p class="m-0">
                        <small class="text-muted">{{userType}} | {{pathologyCompetence ? `Competenza ${pathologyCompetence}` : 'Nessuna competenza patologica'}}</small>
                    </p>
                </div>

            </div>
            
        </nav>
    
    </div>
</template>

<script>

    import store from '@/store'
    
    export default {
        data() {
            return {
                username:            store.getters.infoUser.username,
                userType:            store.getters.userType.title,
                pathologyCompetence: store.getters.pathologyCompetence ? store.getters.pathologyCompetence.title : null
            }
        },
        methods: {
            async logout() {
                try {

                    await store.dispatch('doCentralLogout')

                    this.$router.replace('/')

                }
                catch(e) {
                    this.$toast.open({
                        message: `Non è momentaneamente possibile effettuare il logout`,
                        type:    `error`
                    })
                }
                
            }
        },
        computed: {
            isLogged: () => {
                return store.getters.isLogged
            }
        }
    }
    
</script>