<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <loader v-if="loaders.reading" />

                <div v-else>

                    <!-- Breadcrumb -->
                    <breadcrumb />

                    <div class="card card-body mb-3">

                        <h5 class="font-weight-bold m-0">Associazione paziente con patologia e terapia</h5>

                        <p class="text-muted mb-3">
                            <small>
                                Momentaneamente è possibile modificare la sola durata di un appuntamento e visualizzare le informazioni sulla patologia
                            </small>
                        </p>

                        <form @submit.prevent="updatePatientPathology" autocomplete="off">

                            <div class="row">
                                <div class="col-xl-3 col-lg-6">

                                    <div class="form-group">

                                        <label for="CF">Codice fiscale</label>
                                        <input type="text" class="form-control f-family-monospace" id="CF" :value="this.$route.params.CF" disabled>

                                    </div>

                                    <div v-show="validation.ko" class="alert alert-danger text-center">
                                        {{validation.ko}}
                                    </div> 

                                    <button v-if="!functionDisabled(10, 'update')" class="btn btn-ifo" :disabled="loaders.updating || $v.$invalid">
                                    
                                        <span v-show="!loaders.updating">
                                            Modifica
                                        </span>

                                        <div v-show="loaders.updating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>

                                </div>
                                <div class="col-xl-3 col-lg-6">
                                    <div class="form-group">

                                        <label for="pathology">Patologia</label>
                                        <input type="text" class="form-control" id="pathology" :value="DB.pathology.title" disabled>
                                        <small class="form-text text-muted">Patologia scelta durante l'associazione, che <u>non può essere modificata</u></small>

                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-6">
                                    <div class="form-group">

                                        <label for="sessions_duration">Durata dell'appuntamento</label>
                                        <input type="number" class="form-control" id="sessions_duration" min="15" v-model.trim="$v.form.sessions_duration.$model" v-bind:class="{'is-invalid': (!$v.form.sessions_duration.required || !$v.form.sessions_duration.minValue) && $v.form.sessions_duration.$dirty}">

                                        <p v-show="!$v.form.sessions_duration.$dirty" class="text-muted">
                                            <small>Quantità in minuti</small>
                                        </p>
                                        <p class="text-danger m-0" v-show="(!$v.form.sessions_duration.required || !$v.form.sessions_duration.minValue) && $v.form.sessions_duration.$dirty">
                                            <small>
                                                Impostare almeno 15 minuti
                                            </small>
                                        </p>
                                        <p class="text-danger m-0" v-show="!$v.form.sessions_duration.required && $v.form.sessions_duration.$dirty">
                                            <small>
                                                Durata della seduta richiesto
                                            </small>
                                        </p>

                                    </div>
                                </div>
                            </div>

                        </form>

                    </div>

                    <div class="card card-body mb-3">

                        <h5 class="font-weight-bold m-0">Modifica la terapia associata</h5>

                        <p class="text-muted mb-3">
                            <small>
                                E' possibile modificare la terapia, tenendo traccia di uno storico delle modifiche
                            </small>
                        </p>

                        <div class="alert alert-info">
                            La terapia potrà essere impostata anche durante la creazione/modifica di un appuntamento
                        </div>

                        <div class="row">
                            <div class="col-xl-3 col-lg-6">
                                <form @submit.prevent="update_therapy" autocomplete="off">
                                
                                    <div class="form-group">

                                        <label for="pathologies">Modifica o imposta la terapia</label>

                                        <select class="form-control" id="pathologies" v-model="form_edit_therapy.id_therapy" :disabled="DB.pathologies_therapies.length < 1" v-bind:class="{'is-invalid': DB.pathologies_therapies.length < 1 || validation.ko_update_therapy, 'is-valid': validation.ok_update_therapy}" @input="change_title_new_therapy($event.target.value)">
                                            <option :value="null">
                                                Nessuna terapia
                                            </option>
                                            <option v-for="pathology_therapy of DB.pathologies_therapies" :key="`pathology_therapy_${pathology_therapy.id}`" :value="pathology_therapy.therapy.id">
                                                {{pathology_therapy.therapy.title}}
                                            </option>
                                        </select>
                                        <small class="form-text text-muted">
                                            Seleziona una terapia che dovrà o sta seguendo il paziente
                                        </small>

                                    </div>
                                    <div class="form-group">

                                        <label for="description_edit">Motivazioni sulla modifica</label>

                                        <textarea class="form-control" id="description_edit" rows="3" v-model.trim="$v.form_edit_therapy.description.$model" v-bind:class="{'is-invalid': DB.pathologies_therapies.length < 1 || validation.ko_update_therapy || !$v.form_edit_therapy.description.maxLength, 'is-valid': validation.ok_update_therapy}"></textarea>

                                        <small class="form-text text-muted">
                                            Fornisci una breve spiegazione sulla terapia impostata
                                        </small>
                                        <div class="invalid-feedback" v-show="DB.pathologies_therapies.length < 1">
                                            Non sono state associate terapie alla patologia selezionata
                                        </div>
                                        <div class="invalid-feedback" v-show="validation.ko_update_therapy">
                                            {{validation.ko_update_therapy}}
                                        </div>
                                        <div class="valid-feedback" v-show="validation.ok_update_therapy">
                                            {{validation.ok_update_therapy}}
                                        </div>
                                        <div class="invalid-feedback" v-show="!$v.form_edit_therapy.description.maxLength">
                                            La motivazioni devono essere inferiori a 700 caratteri
                                        </div>

                                    </div>

                                    <button v-if="!functionDisabled(10, 'update')" class="btn btn-ifo mb-3" :disabled="loaders.updating || $v.form_edit_therapy.$invalid">
                                    
                                        <span v-show="!loaders.updating">
                                            Modifica
                                        </span>

                                        <div v-show="loaders.updating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>

                                </form>
                            </div>
                            <div class="col-xl-9 col-lg-6">
                                <b-tabs content-class="border border-top-0">
                                    <b-tab title="Storico delle terapie" active>
                                        <div class="container-fluid py-3">

                                            <div v-if="DB.therapy_cronologies.length < 1" class="alert alert-warning m-0">
                                                Ancora nessuna modifica alla terapia
                                            </div>

                                            <div class="table-responsive m-0" v-else>
                                                <table class="table table-bordered table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                #
                                                            </th>
                                                            <th scope="col">
                                                                Data
                                                            </th>
                                                            <th scope="col">
                                                                Vecchia terapia
                                                            </th>
                                                            <th scope="col">
                                                                Motivazione
                                                            </th>
                                                            <th scope="col">
                                                                Utente
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr tag="tr" v-for="(therapy_cronology, index) of DB.therapy_cronologies" :key="`therapy_cronology_${index}`">
                                                            <th scope="row">
                                                                {{index+1}}
                                                            </th>
                                                            <td>
                                                                {{therapy_cronology.date | data_slash}}
                                                            </td>
                                                            <td>
                                                                {{therapy_cronology.title_old_therapy == null ? "Nessuna terapia" : therapy_cronology.title_old_therapy}}
                                                            </td>
                                                            <td>
                                                                {{therapy_cronology.description == null ? "Nessuna spiegazione" : therapy_cronology.description}}
                                                            </td>
                                                            <td>
                                                                {{therapy_cronology.modifier_user}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>

                    </div>

                    <b-tabs content-class="border border-top-0">
                        <b-tab title="Programma terapeutico" active>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-10 p-3">

                                        <div v-if="!DB.therapy_program" class="alert alert-warning m-0">
                                            Ancora nessun programma terapeutico
                                        </div>

                                        <div class="table-responsive m-0" v-else>
                                            <table class="table table-bordered table-sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            Quantità
                                                        </th>
                                                        <th scope="col">
                                                            Frequenza settimanale
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <router-link tag="tr" :to="`/user/pazienti/modifica/${this.$route.params.CF}/associazione/patologie/modifica/${this.$route.params.id}/programma_terapeutico/modifica/${DB.therapy_program.id}`">
                                                        <td>
                                                            {{DB.therapy_program.quantity}}
                                                        </td>
                                                        <td>
                                                            {{DB.therapy_program.frequency}}
                                                        </td>
                                                    </router-link>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 p-0 border-left">
                                        <ul class="nav flex-column">
                                            <li class="nav-item">
                                                <router-link :to="`/user/pazienti/modifica/${this.$route.params.CF}/associazione/patologie/modifica/${this.$route.params.id}/programma_terapeutico/aggiungi`" class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom" v-bind:class="{'disabled': functionDisabled(10, 'update') || DB.therapy_program}">
                                                    Associa <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle ml-2" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                    </svg>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                    
                </div>

            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a v-b-toggle.elimina v-bind:class="{'disabled': loaders.deleting || functionDisabled(10, 'delete')}" class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom">
                            Elimina <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash ml-2 mt-1" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </a>
                        <b-collapse id="elimina">
                            <div class="alert alert-danger m-0">
                                Sicuro di voler eliminare questa associazione?
                            </div>
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <button @click="deletePatientPathology" class="btn btn-ifo btn-block btn-sm rounded-0" :disabled="loaders.deleting">
                                    
                                        <span v-show="!loaders.deleting">
                                            Si
                                        </span>

                                        <div v-show="loaders.deleting" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>
                                </div>
                                <div class="col-6 pl-0">
                                    <button v-b-toggle.elimina class="btn btn-ifo btn-block btn-sm rounded-0">No</button>
                                </div>
                            </div>
                        </b-collapse>
                    </li>
                    <li class="nav-item">
                        <a @click="suspendPatientPathology" v-bind:class="{'disabled': loaders.suspending || functionDisabled(10, 'update')}" class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom">
                            <span v-show="!loaders.suspending && form.state">
                                Rendi non visibile <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause-circle ml-2" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                </svg>
                            </span>
                            <span v-show="!loaders.suspending && !form.state">
                                Rendi visibile <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle ml-2" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                </svg>
                            </span>

                            <div v-show="loaders.suspending" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                        </a>
                    </li>
                </ul>
            </div>

        </div>  
    </div>
</template>

<script>

    import { 
        required,
        minValue
    } from "vuelidate/lib/validators"

    import moment from "moment"
    import store  from "@/store"

    import { 
        maxLength
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                DB: {
                    patient: null,
                    pathology: null,
                    pathologies_therapies: null,
                    therapy_cronologies: [],
                    therapy_program: null
                },
                form: {
                    id_pathology: null,
                    //sessions_quantity: null,
                    sessions_duration: null,
                    id_therapy: null
                },
                validation: {
                    ko: false,
                    ko_update_therapy: false,
                    ok_update_therapy: false,
                },
                loaders: {
                    updating:               false,
                    reading:                true,
                    deleting:               false,
                    suspending:             false,
                    update_therapy:         false,
                    update_therapy_program: false
                },
                utility: {
                    today: moment().format("YYYY-MM-DD")
                },
                form_edit_therapy: {
                    description:          null,
                    id_patient_pathology: this.$route.params.id,
                    id_therapy:           null,
                    title_old_therapy:    null,
                    therapy_on_select:    null,
                    modifier_user:        this.$store.state.auth.username
                }
            }
        },
        validations() {
            return {
                form: {
                    /*sessions_quantity: {
                        required,
                        minValue: minValue(1)
                    },*/
                    sessions_duration: {
                        required,
                        minValue: minValue(15)
                    }
                },
                form_edit_therapy: {
                    description: {
                        maxLength: maxLength(700)
                    }
                }
            }
        },
        methods: {

            async updatePatientPathology() {

                this.loaders.updating = true
                this.validation.ko    = false
                this.form.id          = this.$route.params.id

                try {

                    const patient_pathology = await this.axios.post(`user/patients_pathologies/update`, this.form, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: patient_pathology.data.msg,
                        type:   `success`
                    })

                    this.$router.push(`/user/pazienti/modifica/${this.$route.params.CF}`)

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko    = message
                    this.loaders.creating = false

                }
                
            },
            async update_therapy() {

                this.loaders.update_therapy       = true
                this.validation.ko_update_therapy = false
                this.validation.ok_update_therapy = false

                try {
                    
                    const therapy_updated = await this.axios.post(`user/patients_pathologies/update_therapy`, this.form_edit_therapy, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    // Modifichiamo il titolo STRING da inserire nella tabella delle "cronologie modifica terapia"
                    this.form_edit_therapy.date = new Date()

                    const OBJ = {...this.form_edit_therapy}
                    
                    // Mostriamo nella tabella la modifica
                    this.DB.therapy_cronologies.push(
                        OBJ
                    )

                    // Impostiamo la nuova terapia scelta
                    this.form_edit_therapy.title_old_therapy = this.form_edit_therapy.therapy_on_select

                    const {
                        message
                    } = therapy_updated.data

                    this.$toast.open({
                        message,
                        type: "success"
                    })

                    this.validation.ok_update_therapy = message

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: "error"
                    })

                    this.validation.ko_update_therapy = message

                }
                finally {
                    this.loaders.update_therapy = false
                }

            },
            async readPatient() {

                const {
                    CF
                } = this.$route.params

                try {

                    const patient = await this.axios.get(`user/patients/read/detail/${CF}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.patient = patient.data

                    for(let patient_pathology of patient.data.patients_pathologies) {
                        if(patient_pathology.id == this.$route.params.id) {

                            this.DB.pathology                        = patient_pathology.pathology
                            this.form.sessions_duration              = patient_pathology.sessions_duration
                            this.DB.pathologies_therapies            = patient_pathology.pathology.pathologies_therapies
                            this.DB.therapy_cronologies              = patient_pathology.therapy_cronologies
                            this.form_edit_therapy.title_old_therapy = patient_pathology.therapy ? patient_pathology.therapy.title : null
                            this.form_edit_therapy.therapy_on_select = patient_pathology.therapy ? patient_pathology.therapy.title : null
                            // Associamo alla form modifica terapia, la terapia precedentemente impostata!
                            this.form_edit_therapy.id_therapy = patient_pathology.id_therapy
                            this.DB.therapy_program                  = patient_pathology.therapy_program
                            
                        }
                    }

                }
                catch(e) {

                    this.$toast.open({
                        message: `Si è verificato un problema, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}` ,
                        type:    `error`
                    })

                    this.$router.go(-1)

                }
                finally {
                    this.loaders.reading = false
                }

            },
            async deletePatientPathology() {
                
                this.loaders.deleting = true

                try {

                    const res = await this.axios.delete(`user/patients_pathologies/${this.$route.params.id}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   `success`
                    })

                    this.$router.push(`/user/pazienti/modifica/${this.$route.params.CF}`)
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   'error'
                    })

                    this.loaders.deleting = false

                }                

            },
            async suspendPatientPathology() {

                this.loaders.suspending = true

                try {

                    const res = await this.axios.get(`user/patients_pathologies/suspend/${this.$route.params.id}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })

                    this.form.state = !this.form.state
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   'error'
                    })

                }
                finally {
                    this.loaders.suspending = false
                }

            },

            functionDisabled(id_function, action) {
                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                }
            },
            change_title_new_therapy(id_therapy) {

                if(!id_therapy) {
                    this.form_edit_therapy.therapy_on_select = null
                }
                else {
                    for(const pathology_therapy of this.DB.pathologies_therapies) {
                        if(pathology_therapy.therapy.id == id_therapy) {
                            this.form_edit_therapy.therapy_on_select = pathology_therapy.therapy.title
                        }
                    }
                }

            }
            
        },
        async mounted() {
            await this.readPatient()
        }
    }

</script>