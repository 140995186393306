<template>
    <div class="container-fluid">

        <div class="row">
            <div class="col-10 py-3 bg-fafafa">

                <breadcrumb />

                <div class="card card-body mb-3">

                    <loader v-if="loaders.read.patients" />

                    <div class="row" v-else>
                        <div class="col-lg-3 d-flex align-items-center justify-content-center justify-content-lg-end">
                            <div class="text-lg-right">
                                <h1 class="f-size-2_2-em m-0">
                                    Pazienti 
                                </h1>
                                <h2 class="f-family-monospace f-size-1_6-em font-weight-bold">
                                    {{this.DB.patients.total}}
                                </h2>
                                <hr>
                                <p class="m-lg-0 text-muted f-size-0_8-em">
                                    Quantità di pazienti presenti nel sistema<br>di Oncoga DH2 suddivisi tra uomo e donna
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-3 mb-lg-0">

                            <doughnut
                                ref         = "skills_chart"
                                :chart-data = "charts.doughnut.patients.data"
                                :options    = "charts.doughnut.patients.options"
                                width       = 300
                                height      = 300
                            />

                        </div>
                        <div class="col-lg-3 d-flex align-items-center">
                            <div class="container">
                                <div class="row f-size-0_8-em">
                                    <div class="col-6 col-xl-3 f-family-monospace font-weight-bold text-right text-truncate">
                                        {{this.DB.patients.man}}
                                    </div>
                                    <div class="col-6 col-xl-9 border-left">
                                        Uomini
                                    </div>
                                </div>
                                <div class="row f-size-0_8-em">
                                    <div class="col-6 col-xl-3 f-family-monospace font-weight-bold text-right text-truncate">
                                        {{this.DB.patients.woman}}
                                    </div>
                                    <div class="col-6 col-xl-9 border-left">
                                        Donne
                                    </div>
                                </div>
                                <div class="row f-size-0_8-em">
                                    <div class="col-6 col-xl-3 f-family-monospace font-weight-bold text-right text-truncate">
                                        {{unknow_sex}}
                                    </div>
                                    <div class="col-6 col-xl-9 border-left">
                                        Non classificati
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
                <div class="card card-body mb-3">

                    <loader v-if="loaders.read.appointments" />

                    <div class="row" v-else>
                        <div class="col-xl-3 d-flex align-items-center justify-content-center justify-content-xl-end">
                            <div class="text-xl-right">
                                <h1 class="f-size-2_2-em m-0">
                                    Appuntamenti 
                                </h1>
                                <h2 class="f-family-monospace f-size-1_6-em font-weight-bold">
                                    {{this.DB.appointments.length}}
                                </h2>
                                <hr>
                                <p class="m-xl-0 text-muted f-size-0_8-em">
                                    Quantità di appuntamenti presenti nel sistema<br>di Oncoga DH2 suddivisi nelle mensilità annuali
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-6 mb-3 mb-xl-0">
                            <linec
                                :chart-data = "charts.line.data"
                                :options    = "charts.line.options"
                                height      = 150
                            />
                        </div>
                        <div class="col-xl-3 d-flex align-items-center">
                            <div class="container">
                                <div class="row f-size-0_8-em" v-for="(a, key) of appointments_for_month" :key="`line_month_${key}`">
                                    <div class="col-6 col-xl-3 f-family-monospace font-weight-bold text-right">
                                        {{a.q}}
                                    </div>
                                    <div class="col-6 col-xl-9 border-left">
                                        {{a.m}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="card card-body mb-3">

                    <loader v-if="loaders.read.pathologies" />

                    <div class="row" v-else>
                        <div class="col-lg-3 d-flex align-items-center justify-content-center justify-content-lg-end">
                            <div class="text-lg-right">
                                <h1 class="f-size-2_2-em m-0">
                                    Patologie 
                                </h1>
                                <h2 class="f-family-monospace f-size-1_6-em font-weight-bold">
                                    {{this.DB.pathologies.length}}
                                </h2>
                                <hr>
                                <p class="m-lg-0 text-muted f-size-0_8-em">
                                    Quantità di patologie presenti nel sistema<br>di Oncoga DH2 assegnate ai pazienti
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-3 mb-lg-0">

                            <doughnut
                                ref         = "skills_chart"
                                :chart-data = "charts.doughnut.pathologies.data"
                                :options    = "charts.doughnut.pathologies.options"
                                width       = 300
                                height      = 300
                            />

                        </div>
                        <div class="col-lg-3 d-flex align-items-center">
                            <div class="container" style="max-height: 300px; overflow: scroll">
                                <div class="row f-size-0_8-em" v-for="p_p of pathologies_how_patients" :key="`pathology_${p_p.t}`">
                                    <div class="col-2 col-xl-3 f-family-monospace font-weight-bold text-right text-truncate">
                                        {{p_p.q}}
                                    </div>
                                    <div class="col-10 col-xl-9 border-left text-truncate">
                                        {{p_p.t}}
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>

                </div>


                <div class="card card-body mb-3">

                    <loader v-if="loaders.read.therapies" />

                    <div class="row" v-else>
                        <div class="col-lg-3 d-flex align-items-center justify-content-center justify-content-lg-end">
                            <div class="text-lg-right">
                                <h1 class="f-size-2_2-em m-0">
                                    Terapie 
                                </h1>
                                <h2 class="f-family-monospace f-size-1_6-em font-weight-bold">
                                    {{this.DB.therapies.length}}
                                </h2>
                                <hr>
                                <p class="m-lg-0 text-muted f-size-0_8-em">
                                    Quantità di terapie presenti nel sistema<br>di Oncoga DH2 assegnate alle patologie
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-3 mb-lg-0">

                            <doughnut
                                ref         = "skills_chart"
                                :chart-data = "charts.doughnut.therapies.data"
                                :options    = "charts.doughnut.therapies.options"
                                width       = 300
                                height      = 300
                            />

                        </div>
                        <div class="col-lg-3 d-flex align-items-center">
                            <div class="container" style="max-height: 300px; overflow: scroll">
                                <div class="row f-size-0_8-em" v-for="p_t of therapies_how_pathologies" :key="`therapy_${p_t.t}`">
                                    <div class="col-2 col-xl-3 f-family-monospace font-weight-bold text-right text-truncate">
                                        {{p_t.q}}
                                    </div>
                                    <div class="col-10 col-xl-9 border-left text-truncate">
                                        {{p_t.t}}
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>

                </div>
                <div class="card card-body mb-3">

                    <loader v-if="loaders.read.drugs" />

                    <div class="row" v-else>
                        <div class="col-xl-3 d-flex align-items-center justify-content-center justify-content-xl-end">
                            <div class="text-xl-right">
                                <h1 class="f-size-2_2-em m-0">
                                    Farmaci 
                                </h1>
                                <h2 class="f-family-monospace f-size-1_6-em font-weight-bold">
                                    {{this.DB.drugs.length}}
                                </h2>
                                <hr>
                                <p class="m-xl-0 text-muted f-size-0_8-em">
                                    Quantità di farmaci presenti nel sistema<br>di Oncoga DH2 suddivisi nelle mensilità annuali
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-6 mb-3 mb-xl-0">
                            <linec
                                :chart-data = "charts.linedrugs.data"
                                :options    = "charts.linedrugs.options"
                                height      = 150
                            />
                        </div>
                        <div class="col-xl-3 d-flex align-items-center">
                            <div class="container">
                                <div class="row f-size-0_8-em" v-for="(a, key) of drugs_for_month" :key="`linedrugs_month_${key}`">
                                    <div class="col-6 col-xl-3 f-family-monospace font-weight-bold text-right">
                                        {{a.q}}
                                    </div>
                                    <div class="col-6 col-xl-9 border-left">
                                        {{a.m}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <navbar />
            </div>
        </div>

    </div>
</template>

<script>

    import moment   from 'moment'
    import doughnut from '@/components/doughnut.vue'
    import linec    from '@/components/line.vue'
    import loader   from '@/components/loader.vue'
    import navbar   from '@/components/navbar_analytics.vue'

    // Set lingua
    moment.locale("IT")

    export default {
        components: {
            doughnut,
            linec,
            loader,
            navbar
        },
        data() {
            return {

                DB: {

                    patients: {
                        total: 0,
                        woman: 0,
                        man:   0
                    },

                    appointments: [],
                    pathologies:  [],
                    therapies:  [],
                    drugs: []
                },

                charts: {
                    doughnut: {
                        patients: {
                            options: {

                                responsive:          true, 
                                maintainAspectRatio: false,
                                cutoutPercentage:    80,

                                animation: {
                                    animateRotate: true
                                },
                                legend: {
                                    display: false
                                }

                            },
                            data: {
                                labels: [
                                    "Uomini",
                                    "Donne",
                                    "Non classificati"
                                ],
                                datasets: [
                                    {
                                        backgroundColor: ["blue", "pink", "grey"],
                                        data:            []
                                    }
                                ]
                            }
                        },
                        pathologies: {
                            options: {

                                responsive:          true, 
                                maintainAspectRatio: false,
                                cutoutPercentage:    80,

                                animation: {
                                    animateRotate: true
                                },
                                legend: {
                                    display: false
                                }

                            },
                            data: {
                                labels: [],
                                datasets: [
                                    {
                                        backgroundColor: [],
                                        data:            []
                                    }
                                ]
                            }
                        },
                        therapies: {
                            options: {

                                responsive:          true, 
                                maintainAspectRatio: false,
                                cutoutPercentage:    80,

                                animation: {
                                    animateRotate: true
                                },
                                legend: {
                                    display: false
                                }

                            },
                            data: {
                                labels: [],
                                datasets: [
                                    {
                                        backgroundColor: [],
                                        data:            []
                                    }
                                ]
                            }
                        }
                       
                    },
                    line: {
                        options: {
                            scales: {
                                y: {
                                    stacked: true
                                }
                            },
                            legend: {
                                display: false
                            }
                        },
                        data: {
                            labels: moment.months(),
                            datasets: [
                                {
                                    //data: this.appointment_for_month,
                                    label: "Appuntamenti",
                                    borderColor: "#5c1b24",
                                    fill: false
                                }
                            ]
                        }
                    },
                    linedrugs: {
                        options: {
                            scales: {
                                y: {
                                    stacked: true
                                }
                            },
                            legend: {
                                display: false
                            }
                        },
                        data: {
                            labels: moment.months(),
                            datasets: [
                                {
                                    //data: this.appointment_for_month,
                                    label: "Farmaci",
                                    borderColor: "#5c1b24",
                                    fill: false
                                }
                            ]
                        }
                    }                   
                },

                loaders: {
                    read: {
                        patients:     true,
                        appointments: true,
                        pathologies:  true,
                        therapies:    true,
                        drugs:        true
                    }
                }

            }
        },
        methods: {
            async read_info_patients() {
                try {

                    const {
                        total,
                        man,
                        woman
                    } = (
                        await this.axios.get(`user/patients/read/analytics`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                    this.DB.patients = {
                        total,
                        man,
                        woman
                    }

                    // Set data chart
                    this.charts.doughnut.patients.data.datasets[0].data[0] = man
                    this.charts.doughnut.patients.data.datasets[0].data[1] = woman
                    this.charts.doughnut.patients.data.datasets[0].data[2] = this.unknow_sex

                    this.$refs.skills_chart.update()

                }
                catch(e) {
                    this.$toast.open({
                        message: e.response.data.msg,
                        type: "error"
                    })
                }
                finally {
                    this.loaders.read.patients = false
                }
            },
            async read_appointments() {
                try {

                    this.DB.appointments = (
                        await this.axios.get(`user/appointments/read_all`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                    // Calcola appuntamenti per mese
                    let appointments = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

                    for(const appointment of this.DB.appointments) {
                        
                        const month = parseInt(
                            moment(appointment.date).format("M")
                        )

                        appointments[month-1]++

                    }                

                    // Set data chart
                    this.charts.line.data.datasets[0].data = appointments

                }
                catch(e) {
                    this.$toast.open({
                        message: e.response.data.msg,
                        type: "error"
                    })
                }
                finally {
                    this.loaders.read.appointments = false
                }
            },
            async read_pathologies() {
                try {

                    this.DB.pathologies = (
                        await this.axios.get(`user/pathologies/read/for_analytic`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                    // Set data chart
                    for(const pathology of this.DB.pathologies) {
                        
                        // Push del titolo della patologia
                        this.charts.doughnut.pathologies.data.labels.push(
                            pathology.title
                        )

                        // Push del colore random
                        this.charts.doughnut.pathologies.data.datasets[0].backgroundColor.push(
                            `#${Math.floor(Math.random()*16777215).toString(16)}`
                        )

                        // Push quantità pazienti
                        this.charts.doughnut.pathologies.data.datasets[0].data.push(
                            pathology.patients_pathologies.length
                        )

                    }

                    this.$refs.skills_chart.update()

                }
                catch(e) {
                    this.$toast.open({
                        message: e.response.data.msg,
                        type: "error"
                    })
                }
                finally {
                    this.loaders.read.pathologies = false
                }
            },
            async read_therapies() {
                try {
                    
                    this.DB.therapies = (
                        await this.axios.get(`user/therapies/read/for_analytic`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data
                    // Set data chart
                    for(const therapy of this.DB.therapies) {
                        
                        // Push del titolo della patologia
                        this.charts.doughnut.therapies.data.labels.push(
                            therapy.title
                        )

                        // Push del colore random
                        this.charts.doughnut.therapies.data.datasets[0].backgroundColor.push(
                            `#${Math.floor(Math.random()*16777215).toString(16)}`
                        )

                        // Push quantità pazienti
                        this.charts.doughnut.therapies.data.datasets[0].data.push(
                            therapy.pathologies_therapies.length
                        )

                    }

                    this.$refs.skills_chart.update()

                }
                catch(e) {
                    this.$toast.open({
                        message: e.response.data.msg,
                        type: "error"
                    })
                }
                finally {
                    this.loaders.read.therapies = false
                }
            },
            async read_drugs() {
                try {

                    this.DB.drugs = (
                        await this.axios.get(`user/appointments/read/drugs`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data
                    // Calcola appuntamenti per mese
                    let appointments = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

                    for(const appointment of this.DB.drugs) {
                        const month = parseInt(
                            moment(appointment.preparation_pharma_start_time).format("M")
                        )

                        appointments[month-1]++

                    }                

                    // Set data chart
                    this.charts.linedrugs.data.datasets[0].data = appointments

                }
                catch(e) {
                    this.$toast.open({
                        message: e.response.data.msg,
                        type: "error"
                    })
                }
                finally {
                    this.loaders.read.drugs = false
                }
            },
          
        },
        computed: {
            unknow_sex() { 
                return this.DB.patients.total - (this.DB.patients.woman + this.DB.patients.man)
            },
            appointments_for_month() {

                // Calcola appuntamenti per mese
                let appointments = [{m:"Gennaio", q:0}, {m:"Febbraio", q:0}, {m:"Marzo", q:0}, {m:"Aprile", q:0}, {m:"Maggio", q:0}, {m:"Giugno", q:0}, {m:"Luglio", q:0}, {m:"Agosto", q:0}, {m:"Settembre", q:0}, {m:"Ottobre", q:0}, {m:"Novembre", q:0}, {m:"Dicembre", q:0}]

                for(const appointment of this.DB.appointments) {
                    
                    const month = parseInt(
                        moment(appointment.date).format("M")
                    )

                    appointments[month-1].q++

                }

                return appointments

            },
            pathologies_how_patients() {

                let pathologies = []

                for(const pathology of this.DB.pathologies) {
                    
                    if(pathology.patients_pathologies.length > 0) {
                        pathologies.push({
                            t: pathology.title,
                            q: pathology.patients_pathologies.length
                        })
                    }

                }

                return pathologies
            },
            therapies_how_pathologies() {

                let therapies = []

                for(const therapy of this.DB.therapies) {
                    if(therapy.pathologies_therapies.length > 0) {
                        therapies.push({
                            t: therapy.title,
                            q: therapy.pathologies_therapies.length
                        })
                    }

                }

                return therapies
            } ,
            drugs_for_month() {

                // Calcola appuntamenti per mese
                let appointments = [{m:"Gennaio", q:0}, {m:"Febbraio", q:0}, {m:"Marzo", q:0}, {m:"Aprile", q:0}, {m:"Maggio", q:0}, {m:"Giugno", q:0}, {m:"Luglio", q:0}, {m:"Agosto", q:0}, {m:"Settembre", q:0}, {m:"Ottobre", q:0}, {m:"Novembre", q:0}, {m:"Dicembre", q:0}]
                
                for(const appointment of this.DB.drugs) {

                    const month = parseInt(
                        moment(appointment.preparation_pharma_start_time).format("M")
                    )

                    appointments[month-1].q++

                }

                return appointments

            },                      
        },
        mounted() {
            this.read_info_patients()
            this.read_appointments()
            this.read_pathologies()
            this.read_therapies()
            this.read_drugs()
        }
    }
</script>