<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 col-md-10 py-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />
                
                <div class="card card-body">

                    <h5 class="font-weight-bold m-0">Aggiungi una nuova stanza</h5>

                    <p class="text-muted mb-3">
                        <small>
                            Ad ogni stanza è possibile assegnare un numero con un orario di inizio e fine attività
                        </small>
                    </p>
                    <!-- @submit.prevent="createRoom" -->
                    <form  autocomplete="off">

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="title">Titolo</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control text-uppercase" id="title" v-model.trim="$v.form.title.$model" v-bind:class="{'is-invalid': !$v.form.title.required && $v.form.title.$dirty}" required>                           
                                    </div>
                                    <p v-if="!$v.form.title.required && $v.form.title.$dirty" class="m-0 text-danger">
                                        <small>
                                            Titolo richiesto
                                        </small>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="form-group">
                                    <label for="title">Numero</label>
                                    <div class="input-group">
                                        <input type="number" class="form-control text-uppercase" maxlength="3" id="number" min="0" v-model.number="$v.form.number.$model" v-bind:class="{'is-invalid': !$v.form.number.required && $v.form.number.$dirty}" required>                           
                                    </div>
                                    <p v-if="!$v.form.number.required && $v.form.number.$dirty" class="m-0 text-danger">
                                        <small>
                                            Numero richiesto
                                        </small>
                                    </p>                                   
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="form-group">
                                    <label for="hour_close">Colore unico della stanza</label>
                                    <div class="input-group">
                                        <input type="color" class="form-control" id="color" v-model.trim="$v.form.color.$model" v-bind:class="{'is-invalid': !$v.form.color.required && $v.form.color.$dirty}" required>                           
                                    </div>
                                    <p v-if="!$v.form.color.required && $v.form.color.$dirty" class="m-0 text-danger">
                                        <small>
                                            Colore richiesto
                                        </small>
                                    </p>
                                </div>
                            </div>                           
                            <!--<div class="col-lg-3">
                                <div class="form-group">
                                    <label for="title">Numero massime di visite giornaliero</label>
                                    <div class="input-group">
                                        <input type="number" class="form-control text-uppercase" maxlength="4" id="max_visits" min="0" v-model.number="$v.form.max_visits.$model" v-bind:class="{'is-invalid': !$v.form.max_visits.required && $v.form.max_visits.$dirty}" required>                           
                                    </div>
                                    <p v-if="!$v.form.max_visits.required && $v.form.max_visits.$dirty" class="m-0 text-danger">
                                        <small>
                                            Numero massime di visite giornaliero richiesto
                                        </small>
                                    </p>
                                </div>
                            </div> -->                          
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label for="description">Descrizione</label>
                                    <div class="input-group">
                                        <textarea class="form-control " id="description" v-model="form.description"></textarea>                           
                                    </div>
                                </div>
                            </div> 
                        </div>  
                        
                        <div class="row">
                            <div class="col-lg-12">
                                <button @click="addAvailability" id="addAvailibility">Aggiungi disponibilità</button>                           
                                <div class="previous hrt" v-for="(availibility, counter) in form.availibilities" v-bind:key="counter">
                                    <div class="alert alert-danger" v-show="validation.conflict">
                                        {{validation.conflict}}
                                    </div>  
                                    <div class="text-right">
                                        <span @click="deleteAvailability(counter)" class="text-danger text-sm-1">Rimuovi</span>                                     
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-6">                                       
                                            <div class="form-group">                                               
                                                <label for="duration">{{counter+1}}. Dal:</label>
                                                <div class="input-group">

                                                    <input type="date" :min="calendar.config.minDate" :max="calendar.config.maxDate" @blur="checkDateDalAl(counter)" class="form-control" v-model="availibility.dal"  required>
                                                </div>
                                            </div>
                                                                                     
                                        </div>       
                                        <div class="col-lg-6">   
                                            <div class="form-group">          
                                                <label for="duration">Al:</label>
                                                <div class="input-group">
                                                    <input type="date" :min="calendar.config.minDate" :max="calendar.config.maxDate" @blur="checkDateDalAl(counter)" class="form-control" v-model="availibility.al" required> 
                                                </div>    
                                            </div>  
                                        </div>
                                    </div>                                  
                                    <div class="row rowbg2">
                                        <div class="col-lg-3">  
                                            <label for="monday"><strong>Lunedì:</strong></label>                                     
                                            <div class="form-group">
                                                <label for="duration">Mattina - dalle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.monday_1.am.dal" @blur="checkTime(counter, 'monday', 'am')" class="form-control">                                                
                                                </div>
                                            </div> 
                                            <div class="form-group">
                                                <label for="duration">Pomeriggio - dalle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.monday_1.pm.dal" @blur="checkTime(counter, 'monday', 'pm')" class="form-control">                                                
                                                </div>
                                            </div>                                                  
                                        </div>       
                                        <div class="col-lg-3">  
                                            <label><br /></label> 
                                            <div class="form-group">          
                                                <label for="duration">alle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.monday_1.am.al" @blur="checkTime(counter, 'monday', 'am')" class="form-control"> 
                                                </div>    
                                            </div>  
                                            <div class="form-group">          
                                                <label for="duration">alle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.monday_1.pm.al" @blur="checkTime(counter, 'monday', 'pm')" class="form-control"> 
                                                </div>    
                                            </div>                                           
                                        </div>

                                        <div class="col-lg-3">  
                                            <label for="monday"><strong>Martedì:</strong></label>                                     
                                            <div class="form-group">
                                                <label for="duration">Mattina - dalle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.tuesday_2.am.dal" @blur="checkTime(counter, 'tuesday', 'am')" class="form-control">                                                
                                                </div>
                                            </div> 
                                            <div class="form-group">
                                                <label for="duration">Pomeriggio - dalle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.tuesday_2.pm.dal" @blur="checkTime(counter, 'tuesday', 'pm')" class="form-control">                                                
                                                </div>
                                            </div>                                                  
                                        </div>       
                                        <div class="col-lg-3">  
                                            <label><br /></label> 
                                            <div class="form-group">          
                                                <label for="duration">alle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.tuesday_2.am.al" @blur="checkTime(counter, 'tuesday', 'am')" class="form-control"> 
                                                </div>    
                                            </div>  
                                            <div class="form-group">          
                                                <label for="duration">alle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.tuesday_2.pm.al" @blur="checkTime(counter, 'tuesday', 'pm')" class="form-control"> 
                                                </div>    
                                            </div>                                           
                                        </div>                                       
                                    </div>   
                                    
                                    <div class="row rowbg">
                                        <div class="col-lg-3">  
                                            <label for="monday"><strong>Mercoledì:</strong></label>                                     
                                            <div class="form-group">
                                                <label for="duration">Mattina - dalle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.wednesday_3.am.dal" @blur="checkTime(counter, 'wednesday', 'am')" class="form-control">                                                
                                                </div>
                                            </div> 
                                            <div class="form-group">
                                                <label for="duration">Pomeriggio - dalle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.wednesday_3.pm.dal" @blur="checkTime(counter, 'wednesday', 'pm')"  class="form-control">                                                
                                                </div>
                                            </div>                                                  
                                        </div>       
                                        <div class="col-lg-3">  
                                            <label><br /></label> 
                                            <div class="form-group">          
                                                <label for="duration">alle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.wednesday_3.am.al" @blur="checkTime(counter, 'wednesday', 'am')" class="form-control"> 
                                                </div>    
                                            </div>  
                                            <div class="form-group">          
                                                <label for="duration">alle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.wednesday_3.pm.al" @blur="checkTime(counter, 'wednesday', 'pm')" class="form-control"> 
                                                </div>    
                                            </div>                                           
                                        </div>

                                        <div class="col-lg-3">  
                                            <label for="monday"><strong>Giovedì:</strong></label>                                     
                                            <div class="form-group">
                                                <label for="duration">Mattina - dalle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.thursday_4.am.dal" @blur="checkTime(counter, 'thursday', 'am')" class="form-control">                                                
                                                </div>
                                            </div> 
                                            <div class="form-group">
                                                <label for="duration">Pomeriggio - dalle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.thursday_4.pm.dal" @blur="checkTime(counter, 'thursday', 'pm')" class="form-control">                                                
                                                </div>
                                            </div>                                                  
                                        </div>       
                                        <div class="col-lg-3">  
                                            <label><br /></label> 
                                            <div class="form-group">          
                                                <label for="duration">alle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.thursday_4.am.al" @blur="checkTime(counter, 'thursday', 'am')" class="form-control"> 
                                                </div>    
                                            </div>  
                                            <div class="form-group">          
                                                <label for="duration">alle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.thursday_4.pm.al" @blur="checkTime(counter, 'thursday', 'pm')" class="form-control"> 
                                                </div>    
                                            </div>                                           
                                        </div>                                       
                                    </div>
                                    
                                    <div class="row rowbg2 hr">
                                        <div class="col-lg-3">  
                                            <label for="monday"><strong>Venerdì:</strong></label>                                     
                                            <div class="form-group">
                                                <label for="duration">Mattina - dalle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.friday_5.am.dal" @blur="checkTime(counter, 'friday', 'am')" class="form-control">                                                
                                                </div>
                                            </div> 
                                            <div class="form-group">
                                                <label for="duration">Pomeriggio - dalle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.friday_5.pm.dal" @blur="checkTime(counter, 'friday', 'pm')" class="form-control">                                                
                                                </div>
                                            </div>                                                  
                                        </div>       
                                        <div class="col-lg-3">  
                                            <label><br /></label> 
                                            <div class="form-group">          
                                                <label for="duration">alle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.friday_5.am.al" @blur="checkTime(counter, 'friday', 'am')" class="form-control"> 
                                                </div>    
                                            </div>  
                                            <div class="form-group">          
                                                <label for="duration">alle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.friday_5.pm.al" @blur="checkTime(counter, 'friday', 'pm')" class="form-control"> 
                                                </div>    
                                            </div>                                           
                                        </div>

                                        <div class="col-lg-3">  
                                            <label for="monday"><strong>Sabato:</strong></label>                                     
                                            <div class="form-group">
                                                <label for="duration">Mattina - dalle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.saturday_6.am.dal" @blur="checkTime(counter, 'saturday', 'am')" class="form-control">                                                
                                                </div>
                                            </div> 
                                            <div class="form-group">
                                                <label for="duration">Pomeriggio - dalle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.saturday_6.pm.dal" @blur="checkTime(counter, 'saturday', 'pm')" class="form-control">                                                
                                                </div>
                                            </div>                                                  
                                        </div>       
                                        <div class="col-lg-3">  
                                            <label><br /></label> 
                                            <div class="form-group">          
                                                <label for="duration">alle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.saturday_6.am.al" @blur="checkTime(counter, 'saturday', 'am')" class="form-control"> 
                                                </div>    
                                            </div>  
                                            <div class="form-group">          
                                                <label for="duration">alle ore:</label>
                                                <div class="input-group">
                                                    <input type="time" v-model="availibility.saturday_6.pm.al" @blur="checkTime(counter, 'saturday', 'pm')" class="form-control"> 
                                                </div>    
                                            </div>                                           
                                        </div>                                       
                                    </div>                                                     
                                </div>                               
                            </div>
                        </div>                            
                        <div v-show="validation.ko" class="alert alert-danger text-center">
                            {{validation.ko}}
                        </div>

                        <button @click="createRoom" class="btn btn-ifo" :disabled="$v.$invalid || loaders.creating">
                                
                            <span v-show="!loaders.creating">
                                Aggiungi
                            </span>

                            <div v-show="loaders.creating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                        </button>

                    </form>

                </div>
                
            </div>
            <div class="col-2 col-md-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column d-none d-md-block">
                    <li class="nav-item">
                        <a href class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom disabled">
                            <i class="text-muted">
                                Nessuna funzione
                            </i>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
        
    </div>
</template>

<script>

    import { 
        required
    } from "vuelidate/lib/validators"

    import moment from "moment"

    export default {
        data() {            
            return {
                pathologies: {
                    DB: []
                },
                form: {
                    title:       null,
                    number:       null,
                    color:       `#ffad08`,
                    description: null,
                    availibilities: [{
                        dal:'',
                        al: '',
                        monday_1: {
                            am: {
                                dal: '',
                                al: ''
                            },
                            pm: {
                                dal: '',
                                al: ''
                            }                                                   
                        },
                        tuesday_2: {
                            am: {
                                dal: '',
                                al: ''
                            },
                            pm: {
                                dal: '',
                                al: ''
                            }                                                   
                        },
                        wednesday_3: {
                            am: {
                                dal: '',
                                al: ''
                            },
                            pm: {
                                dal: '',
                                al: ''
                            }                                                   
                        },
                        thursday_4: {
                            am: {
                                dal: '',
                                al: ''
                            },
                            pm: {
                                dal: '',
                                al: ''
                            }                                                   
                        } ,
                        friday_5: {
                            am: {
                                dal: '',
                                al: ''
                            },
                            pm: {
                                dal: '',
                                al: ''
                            }                                                   
                        } ,
                        saturday_6: {
                            am: {
                                dal: '',
                                al: ''
                            },
                            pm: {
                                dal: '',
                                al: ''
                            }                                                   
                        }                                                                                                                                                                     
                    }]                 
                },
                validation: {
                    ko: false
                },
                loaders: {
                    creating: false
                },
                utility: {
                    today: moment().format("YYYY-MM-DD")
                },
                calendar: {
                    config: {
                        minDate:      moment().format("YYYY-MM-DD"),
                        maxDate:      null,
                        timeFrom:     8*60,
                        timeTo:       20*60,
                        timeStep:     30,
                        roomSelected: {},
                        events:       [],
                        hideWeekDays: [],
                        selectedDate: null,
                        specialHours: null
                    }
                }                              
            }
        },
        validations() {
            return {
                form: {
                    title: {
                        required
                    },                    
                    number: {
                        required
                    },
                    color: {
                        required
                    }                               
                }
            }
        },
        methods: {
            addAvailability(){
                this.form.availibilities.push({
                    dal:'',
                    al: '',
                    monday_1: {
                        am: {
                            dal: '',
                            al: ''
                        },
                        pm: {
                            dal: '',
                            al: ''
                        }                                                   
                    },
                    tuesday_2: {
                        am: {
                            dal: '',
                            al: ''
                        },
                        pm: {
                            dal: '',
                            al: ''
                        }                                                   
                    },
                    wednesday_3: {
                        am: {
                            dal: '',
                            al: ''
                        },
                        pm: {
                            dal: '',
                            al: ''
                        }                                                   
                    },
                    thursday_4: {
                        am: {
                            dal: '',
                            al: ''
                        },
                        pm: {
                            dal: '',
                            al: ''
                        }                                                   
                    } ,
                    friday_5: {
                        am: {
                            dal: '',
                            al: ''
                        },
                        pm: {
                            dal: '',
                            al: ''
                        }                                                   
                    } ,
                    saturday_6: {
                        am: {
                            dal: '',
                            al: ''
                        },
                        pm: {
                            dal: '',
                            al: ''
                        }                                                   
                    }                        
                })
            },
            deleteAvailability(counter){
                this.form.availibilities.splice(counter,1);   
            }, 
            checkDateDalAl(counter) {

                const date_dal   = moment(this.form.availibilities[counter].dal).format("YYYYMMDD")
                const date_al   = moment(this.form.availibilities[counter].al).format("YYYYMMDD")    

                const conflitto = (
                    date_dal > date_al
                ) 

                if(conflitto) {

                    const message = "Data disponibilità DAL maggiore di data AL"

                    this.$toast.open({
                        message,
                        type: "warning"
                    })                   
                    this.validation.conflict = message
                    this.validation.ko    = message
                    this.loaders.updating = true                   
                } else {
                    this.validation.conflict = false
                    this.validation.ko    = false 
                    this.loaders.updating = false                  
                }
            }, 
            checkTime(counter, dayweek, moraft) {
                var time_dal;
                var time_al;

                var nameDay;
                var namemoraft;

                if(dayweek == "monday" && moraft == "am" && this.form.availibilities[counter].monday_1.am.al != ""){
                    time_dal = this.form.availibilities[counter].monday_1.am.dal; 
                    time_al = this.form.availibilities[counter].monday_1.am.al; 
                    nameDay = "lunedì";
                    namemoraft = "mattina";
                } else if(dayweek == "monday" && moraft == "pm" && this.form.availibilities[counter].monday_1.pm.al != "") {
                    time_dal = this.form.availibilities[counter].monday_1.pm.dal; 
                    time_al = this.form.availibilities[counter].monday_1.pm.al; 
                    nameDay = "lunedì";
                    namemoraft = "pomeriggio";                   
                } else if(dayweek == "tuesday" && moraft == "am" && this.form.availibilities[counter].tuesday_2.am.al != ""){
                    time_dal = this.form.availibilities[counter].tuesday_2.am.dal; 
                    time_al = this.form.availibilities[counter].tuesday_2.am.al; 
                    nameDay = "martedì";
                    namemoraft = "mattina";                   
                } else if(dayweek == "tuesday" && moraft == "pm" && this.form.availibilities[counter].tuesday_2.pm.al != "") {
                    time_dal = this.form.availibilities[counter].tuesday_2.pm.dal; 
                    time_al = this.form.availibilities[counter].tuesday_2.pm.al; 
                    nameDay = "martedì";
                    namemoraft = "pomeriggio";                        
                } else if(dayweek == "wednesday" && moraft == "am" && this.form.availibilities[counter].wednesday_3.am.al != ""){
                    time_dal = this.form.availibilities[counter].wednesday_3.am.dal; 
                    time_al = this.form.availibilities[counter].wednesday_3.am.al; 
                    nameDay = "mercoledì";
                    namemoraft = "mattina";                     
                } else if(dayweek == "wednesday" && moraft == "pm" && this.form.availibilities[counter].wednesday_3.pm.al != "") {
                    time_dal = this.form.availibilities[counter].wednesday_3.pm.dal; 
                    time_al = this.form.availibilities[counter].wednesday_3.pm.al; 
                    nameDay = "mercoledì";
                    namemoraft = "pomeriggio";                     
                } else if(dayweek == "thursday" && moraft == "am" && this.form.availibilities[counter].thursday_4.am.al != ""){
                    time_dal = this.form.availibilities[counter].thursday_4.am.dal; 
                    time_al = this.form.availibilities[counter].thursday_4.am.al; 
                    nameDay = "giovedì";
                    namemoraft = "mattina";                    
                } else if(dayweek == "thursday" && moraft == "pm" && this.form.availibilities[counter].thursday_4.pm.al != "") {
                    time_dal = this.form.availibilities[counter].thursday_4.pm.dal; 
                    time_al = this.form.availibilities[counter].thursday_4.pm.al; 
                    nameDay = "giovedì";
                    namemoraft = "pomeriggio";                     
                } else if(dayweek == "friday" && moraft == "am" && this.form.availibilities[counter].friday_5.am.al != ""){
                    time_dal = this.form.availibilities[counter].friday_5.am.dal; 
                    time_al = this.form.availibilities[counter].friday_5.am.al; 
                    nameDay = "venerdì";
                    namemoraft = "mattina";                     
                } else if(dayweek == "friday" && moraft == "pm" && this.form.availibilities[counter].friday_5.pm.al != "") {
                    time_dal = this.form.availibilities[counter].friday_5.pm.dal; 
                    time_al = this.form.availibilities[counter].friday_5.pm.al; 
                    nameDay = "venerdì";
                    namemoraft = "pomeriggio";                       
                } else if(dayweek == "saturday" && moraft == "am" && this.form.availibilities[counter].saturday_6.am.al != ""){
                    time_dal = this.form.availibilities[counter].saturday_6.am.dal; 
                    time_al = this.form.availibilities[counter].saturday_6.am.al; 
                    nameDay = "sabato";
                    namemoraft = "mattina";                      
                } else if(dayweek == "saturday" && moraft == "pm" && this.form.availibilities[counter].saturday_6.pm.al != "") {
                    time_dal = this.form.availibilities[counter].saturday_6.pm.dal; 
                    time_al = this.form.availibilities[counter].saturday_6.pm.al; 
                    nameDay = "sabato";
                    namemoraft = "pomeriggio";                      
                }      

                time_dal = time_dal.replace(':', '');
                time_al = time_al.replace(':', '');

                const conflitto = (
                    time_dal > time_al
                ) 

                if(conflitto) {
                    const message = "Orario "+ nameDay + " " + namemoraft + " errato"
                    this.$toast.open({
                        message,
                        type: "warning"
                    })                   
                    this.validation.conflict = message
                    this.validation.ko    = message
                    this.loaders.updating = true                   
                } else {
                    this.validation.conflict = false
                    this.validation.ko    = false 
                    this.loaders.updating = false                  
                }
            },                                                 
            async createRoom() {
                this.loaders.creating = true
                this.validation.ko    = false              

                try {
                    const res = await this.axios.post(`user/rooms/create`, this.form, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })
                
                    this.$router.push(`/user/stanze`) 
      
                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: 'error'
                    })

                    this.validation.ko    = message
                    this.loaders.creating = false

                }
                
            }
        }
    }
</script>