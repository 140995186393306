<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />

                <loader v-if="loaders.reading" />

                <div class="card card-body" v-else>
                    <form @submit.prevent="update_software_function" autocomplete="off">
                        <div class="row">
                            <div class="col-lg-5">
                                <div class="form-group">

                                    <label for="user_software_function">Funzionalità</label>
                                    <input type="text" class="form-control text-lowercase" id="user_software_function" :value="$route.params.software_function" disabled>

                                    <small class="form-text text-muted">
                                        La funzionalità non può essere modificata
                                    </small>

                                </div>
                                <div class="custom-control custom-checkbox text-truncate">
                                    <input type="checkbox" class="custom-control-input" id="create" v-model="form.create">
                                    <label class="custom-control-label" for="create">Creazione</label>
                                </div>
                                <div class="custom-control custom-checkbox text-truncate">
                                    <input type="checkbox" class="custom-control-input" id="read" v-model="form.read">
                                    <label class="custom-control-label" for="read">Lettura</label>
                                </div>
                                <div class="custom-control custom-checkbox text-truncate">
                                    <input type="checkbox" class="custom-control-input" id="update" v-model="form.update">
                                    <label class="custom-control-label" for="update">Modifica</label>
                                </div>
                                <div class="custom-control custom-checkbox text-truncate mb-3">
                                    <input type="checkbox" class="custom-control-input" id="delete" v-model="form.delete">
                                    <label class="custom-control-label" for="delete">Eliminazione</label>
                                </div>

                                <button class="btn btn-ifo">
                                    Modifica
                                </button>

                            </div>
                        </div>
                    </form>
                </div>

            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a v-b-toggle.elimina v-bind:class="{'disabled': loaders.deleting}" class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom">
                            Elimina <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash ml-2 mt-1" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </a>
                        <b-collapse id="elimina">
                            <div class="alert alert-danger m-0">
                                Sicuro di voler eliminare questa funzionalità?
                            </div>
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <button @click="delete_software_function" class="btn btn-ifo btn-block btn-sm rounded-0" :disabled="loaders.deleting">
                                    
                                        <span v-show="!loaders.deleting">
                                            Si
                                        </span>

                                        <div v-show="loaders.deleting" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>
                                </div>
                                <div class="col-6 pl-0">
                                    <button v-b-toggle.elimina class="btn btn-ifo btn-block btn-sm rounded-0">No</button>
                                </div>
                            </div>
                        </b-collapse>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    create: false,
                    read:   false,
                    update: false,
                    delete: false
                },
                loaders: {
                    reading:  true,
                    updating: false,
                    deleting: false
                }
            }
        },
        methods: {
            async read_software_function() {
                try {

                    this.form = (
                        await this.axios.post(`user/users_software_functions/read/detail`, {title_user_type: this.$route.params.title, title_software_function: this.$route.params.software_function}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data.user_software_function

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                }
                finally {
                    this.loaders.reading = false
                }
            },
            async update_software_function() {
                try {

                    await this.axios.post(`user/users_software_functions/update`, this.form, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: "Autorizzazione modificata con successo",
                        type:    "success"
                    })

                    this.$router.push(`/user/utenti/tipologie/modifica/${this.$route.params.title}`)

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                }
            },
            async delete_software_function() {

                this.loaders.deleting = true

                try {

                    await this.axios.delete(`user/users_software_functions/${this.form.id}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: "Autorizzazione eliminata con successo",
                        type:    "success"
                    })

                    this.$router.push(`/user/utenti/tipologie/modifica/${this.$route.params.title}`)
                    
                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: "error"
                    })

                    this.loaders.deleting = false

                }
            }
        },
        mounted() {
            this.read_software_function()
        }
    }
</script>