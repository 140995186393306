<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 col-md-10 py-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />
                
                <div class="card card-body">

                    <h5 class="font-weight-bold m-0">Aggiungi un nuovo tipo di visita</h5>

                    <p class="text-muted mb-3">
                        <small>
                            Ad ogni tipo di visita è possibile assegnare una durata
                        </small>
                    </p>
                    <!-- @submit.prevent="createRoom" -->
                    <form  autocomplete="off">

                        <div class="row">
                            <div class="col-lg-9">
                                <div class="form-group">
                                    <label for="title">Titolo</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control text-uppercase" id="title" v-model.trim="$v.form.title.$model" v-bind:class="{'is-invalid': !$v.form.title.required && $v.form.title.$dirty}" required>                           
                                    </div>
                                    <p v-if="!$v.form.title.required && $v.form.title.$dirty" class="m-0 text-danger">
                                        <small>
                                            Titolo richiesto
                                        </small>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="form-group">
                                    <label for="title">Durata (in minuti)</label>
                                    <div class="input-group">
                                        <input type="number" class="form-control text-uppercase" maxlength="3" @keyup="onlyNumeric" id="duration" min="0" v-model.number="$v.form.duration.$model" v-bind:class="{'is-invalid': !$v.form.duration.required && $v.form.duration.$dirty}" required>                           
                                    </div>
                                    <p v-if="!$v.form.duration.required && $v.form.duration.$dirty" class="m-0 text-danger">
                                        <small>
                                            Durata richiesto
                                        </small>
                                    </p>                                   
                                </div>
                            </div>
                            <!--<div class="col-lg-3">
                                <div class="form-group">
                                    <label for="title">Numero massime di visite giornaliero</label>
                                    <div class="input-group">
                                        <input type="number" class="form-control text-uppercase" maxlength="4" id="max_visits" min="0" v-model.number="$v.form.max_visits.$model" v-bind:class="{'is-invalid': !$v.form.max_visits.required && $v.form.max_visits.$dirty}" required>                           
                                    </div>
                                    <p v-if="!$v.form.max_visits.required && $v.form.max_visits.$dirty" class="m-0 text-danger">
                                        <small>
                                            Numero massime di visite giornaliero richiesto
                                        </small>
                                    </p>
                                </div>
                            </div> -->                          
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label for="description">Descrizione</label>
                                    <div class="input-group">
                                        <textarea class="form-control " id="description" v-model="form.description"></textarea>                           
                                    </div>
                                </div>
                            </div> 
                        </div>                          
                        <div v-show="validation.ko" class="alert alert-danger text-center">
                            {{validation.ko}}
                        </div>

                        <button @click="createVisitType" class="btn btn-ifo" :disabled="$v.$invalid || loaders.creating">
                                
                            <span v-show="!loaders.creating">
                                Aggiungi
                            </span>

                            <div v-show="loaders.creating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                        </button>

                    </form>

                </div>
                
            </div>
            <div class="col-2 col-md-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column d-none d-md-block">
                    <li class="nav-item">
                        <a href class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom disabled">
                            <i class="text-muted">
                                Nessuna funzione
                            </i>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
        
    </div>
</template>

<script>

    import { 
        required
    } from "vuelidate/lib/validators"

    export default {
        data() {            
            return {
                pathologies: {
                    DB: []
                },
                form: {
                    title:       null,
                    duration:    null,
                    description: null    
                },
                validation: {
                    ko: false
                },
                loaders: {
                    creating: false
                }                         
            }
        },
        validations() {
            return {
                form: {
                    title: {
                        required
                    },
                    duration: {
                        required
                    }            
                }
            }
        },
        methods: {  
            onlyNumeric(e) {
                e.target.value = e.target.value.replace(/[^0-9]+/g, '')
            } ,                             
            async createVisitType() {
                this.loaders.creating = true
                this.validation.ko    = false              

                try {
                    const res = await this.axios.post(`user/visit_types/create`, this.form, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })
                
                    this.$router.push(`/user/tipo_visita`) 
      
                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: 'error'
                    })

                    this.validation.ko    = message
                    this.loaders.creating = false

                }
                
            }
        }
    }
</script>