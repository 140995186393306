<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />
                
                <div class="card card-body">

                    <h5 class="font-weight-bold m-0">Aggiungi un nuovo utente</h5>

                    <p class="text-muted mb-3">
                        <small>
                            In questa schermata puoi creare/registrare una nuova utenza
                        </small>
                    </p>

                    <form @submit.prevent="createUser" autocomplete="off">

                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="username">Username</label>

                                    <input type="text" class="form-control text-lowercase" id="username" v-model.trim="$v.form.user.username.$model" v-bind:class="{'is-invalid': !$v.form.user.username.required && $v.form.user.username.$dirty}">
                                    
                                    <p v-if="!$v.form.user.username.$dirty" class="m-0 text-muted">
                                        <small>L'username dovrà essere univoco</small>
                                    </p>
                                    <p v-if="!$v.form.user.username.required && $v.form.user.username.$dirty" class="m-0 text-danger">
                                        <small>Username richiesto</small>
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="name">Nome</label>

                                    <input type="text" class="form-control text-uppercase" id="name" v-model.trim="$v.form.user.name.$model" v-bind:class="{'is-invalid': !$v.form.user.name.required && $v.form.user.name.$dirty}">
                                    
                                    <p v-if="!$v.form.user.name.required && $v.form.user.name.$dirty" class="m-0 text-danger">
                                        <small>Nome richiesto</small>
                                    </p>

                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="surname">Cognome</label>

                                    <input type="text" class="form-control text-uppercase" id="surname" v-model.trim="$v.form.user.surname.$model" v-bind:class="{'is-invalid': !$v.form.user.surname.required && $v.form.user.surname.$dirty}">
                                    
                                    <p v-if="!$v.form.user.surname.required && $v.form.user.surname.$dirty" class="m-0 text-danger">
                                        <small>Cognome richiesto</small>
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="email">Email</label>

                                    <input type="email" class="form-control text-lowercase" id="email" v-model.trim="$v.form.user.email.$model" v-bind:class="{'is-invalid': (!$v.form.user.email.required || !$v.form.user.email.email) && $v.form.user.email.$dirty}">
                                    
                                    <p v-if="!$v.form.user.email.required && $v.form.user.email.$dirty" class="m-0 text-danger">
                                        <small>Email richiesta</small>
                                    </p>
                                    <p v-if="!$v.form.user.email.email && $v.form.user.email.$dirty" class="m-0 text-danger">
                                        <small>Email errata</small>
                                    </p>

                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="phone">Cellulare</label>

                                    <input type="phone" class="form-control" id="phone" v-model.trim="$v.form.user.phone.$model" v-bind:class="{'is-invalid': (!$v.form.user.phone.numeric) && $v.form.user.phone.$dirty}">
                                    
                                    <p v-if="!$v.form.user.phone.numeric && $v.form.user.phone.$dirty" class="m-0 text-danger">
                                        <small>Non puoi inserire lettere nel numero</small>
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="pathology_competence">Patologia di competenza</label>

                                    <select class="form-control" id="pathology_competence" v-model="form.user.id_pathology_competence">

                                        <option selected value="null">Nessuna patologia</option>

                                        <option v-for="(pathology, index) of DB.pathologies" v-bind:key="index" v-bind:value="pathology.id">
                                            {{pathology.title}}
                                        </option>


                                    </select>

                                    <p class="m-0 text-muted">
                                        <small>Associa l'utente ad una patologia, per personalizzare l'esperienza nel software</small>
                                    </p>

                                </div>
                            </div>
                        </div>

                        <p class="font-weight-bold m-0">Tipologia</p>

                        <p class="text-muted mb-3">
                            <small>
                                Seleziona una tipologia di utenza che verrà ricoperta, ricorda che ciascuna ha dei privilegi specifici
                            </small>
                        </p>
                        
                        <div class="form-group" v-for="(userType, index) of DB.userTypes" :key="`user_type_${index}`">
                            <div class="custom-control custom-radio">

                                <input type="radio" :id="`user_type_${index}`" :value="userType.id" name="userType" class="custom-control-input" v-model="form.user.id_user_type" required>

                                <label class="custom-control-label" :for="`user_type_${index}`">
                                    {{userType.title}}
                                </label>
                                
                            </div>
                        </div>

                        <button class="btn btn-ifo" :disabled="$v.$invalid || !form.user.id_user_type || loader.creating">

                            <span v-show="!loader.creating">Aggiungi</span>
                            <div v-show="loader.creating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                        </button>

                    </form>

                </div>
            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a href class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom disabled">
                            <i class="text-muted">
                                Nessuna funzione
                            </i>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
        
    </div>
</template>

<script>

    import { 
        required,
        email,
        numeric
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                form: {
                    user: {
                        username:                ``,
                        name:                    ``,
                        surname:                 ``,
                        email:                   ``,
                        phone:                   ``,
                        id_user_type:            null,
                        id_pathology_competence: null
                    }
                },
                DB: {
                    userTypes:   [],
                    pathologies: []
                },
                loader: {
                    creating: false,
                    reading: {
                        pathologies: false
                    }
                }
            }
        },
        validations() {
            return {
                form: {
                    user: {
                        username: {
                            required
                        },
                        name: {
                            required
                        },
                        surname: {
                            required
                        },
                        email: {
                            required,
                            email
                        },
                        phone: {
                            numeric
                        }
                    }
                }
            }
        },
        methods: {
            async readUserTypes() {

                try {

                    const userTypes = await this.axios.get(`user/user_types/read/actives`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.userTypes = userTypes.data

                }
                catch(e) {
                    console.log(e)
                }

            },
            async createUser() {

                this.loader.creating = true

                try {

                    const res = await this.axios.post(`auth/register`, this.form.user, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   `success`
                    })

                    this.$router.push(`/user/utenti`)

                }
                catch(e) {

                    const message = `Si è verificato un problema, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}`

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko   = message
                    this.loader.creating = false
                }
                
            },
            async readPathologies() {

                this.loader.reading.pathologies = true
                
                try {

                    const pathologies = await this.axios.get(`user/pathologies/read/actives`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.pathologies = pathologies.data

                }
                catch(e) {
                    this.$toast.open({
                        message: `Si è verificato un problema durante la ricerca delle patologie da associare all'utente, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}`,
                        type:    `error`
                    })
                }
                finally {
                    this.loader.reading.pathologies = false
                }

            }
        },
        mounted() {
            this.readUserTypes()
            this.readPathologies()
        }
    }
</script>