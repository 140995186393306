import Vue    from 'vue'
import moment from 'moment'

Vue.filter('data_ora', function(value) {
    if (value) {
        return moment(String(value)).format('HH:mm')
    }
})
Vue.filter('data_slash', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
})
Vue.filter('data_underscore', function(value) {
    if (value) {
        return moment(String(value)).format('DD_MM_YYYY')
    }
})
Vue.filter('DD/MM', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM')
    }
})