<template>
    <div class="pt-3 pb-3 bg-fafafa container-fluid d-flex">

        <div class="card card-body m-auto col-lg-4 col-md-6">

            <h5 class="font-weight-bold m-0">Crea la tua password</h5>

            <p class="text-muted mb-3">
                <small>
                    Risulti essere un nuovo utente con la tua email, crea la tua password personale
                </small>
            </p>

            <form @submit.prevent="updatePassword" autocomplete="off">

                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="email" class="form-control text-lowercase" id="email" v-model="form.email" disabled>
                </div>
                <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" class="form-control text-lowercase" id="username" v-model="form.username" disabled>
                </div>
                <div class="form-group">

                    <label for="password">Password</label>

                    <div class="input-group">

                        <input :type="utilities.show.password ? 'text' : 'password'" class="form-control" id="password" v-model.trim="$v.form.password.$model" v-bind:class="{'is-invalid': (!$v.form.password.required || (!$v.utilities.confirm.password.equalsPassword && $v.utilities.confirm.password.$dirty) || !$v.form.password.minLength || !$v.form.password.specialChar) && $v.form.password.$dirty, 'is-valid': validation.ok}">
                        
                        <div class="input-group-append">
                            <button class="btn btn-ifo d-flex" type="button" @click="utilities.show.password =! utilities.show.password">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-eye m-auto" viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                </svg>
                            </button>
                        </div>

                    </div>
                    <p v-if="!$v.form.password.$dirty" class="m-0 text-muted">
                        <small>Password di almeno 8 caratteri, che contiene almeno uno di questi caratteri <b>! # $ & ( ) _ + - = ; ' : " , . ?</b></small>
                    </p>
                    <p v-if="!$v.form.password.required && $v.form.password.$dirty" class="m-0 text-danger">
                        <small>Password richiesta</small>
                    </p>
                    <p v-if="!$v.form.password.minLength && $v.form.password.$dirty" class="m-0 text-danger">
                        <small>Minimo 8 caretteri</small>
                    </p>
                    <p v-if="!$v.form.password.specialChar && $v.form.password.$dirty" class="m-0 text-danger">
                        <small>Deve contentere almeno un carattere speciale tra questi <b>! # $ & ( ) _ + - = ; ' : " , . ?</b></small>
                    </p>

                </div>
                <div class="form-group">

                    <label for="conferma_password">Conferma password</label>

                    <div class="input-group">

                        <input :type="utilities.show.confirm_password ? 'text' : 'password'" class="form-control" id="conferma_password" v-model.trim="$v.utilities.confirm.password.$model" v-bind:class="{'is-invalid': (!$v.utilities.confirm.password.required || !$v.utilities.confirm.password.equalsPassword) && $v.utilities.confirm.password.$dirty, 'is-valid': validation.ok}">
                        
                        <div class="input-group-append">
                            <button class="btn btn-ifo d-flex" type="button" @click="utilities.show.confirm_password =! utilities.show.confirm_password">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-eye m-auto" viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                </svg>
                            </button>
                        </div>

                    </div>
                    
                    <p v-if="!$v.utilities.confirm.password.required && $v.utilities.confirm.password.$dirty" class="m-0 text-danger">
                        <small>Conferma password richiesta</small>
                    </p>
                    <p v-if="!$v.utilities.confirm.password.equalsPassword && $v.utilities.confirm.password.$dirty" class="m-0 text-danger">
                        <small>Conferma password differente dalla password</small>
                    </p>

                </div>

                <div v-show="validation.ko" class="alert alert-danger">
                    {{validation.ko}}
                </div>
                <div v-show="validation.ok" class="alert alert-info">
                    {{validation.ok}}
                </div>

                <button class="btn btn-ifo" :disabled="$v.$invalid || loaders.updating">
                        
                    <span v-show="!loaders.updating">
                        Crea
                    </span>

                    <div v-show="loaders.updating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                </button>
                <router-link to="/" class="btn btn-ifo ml-2">
                        
                    <span v-show="!loaders.updating">
                        Home
                    </span>

                </router-link>

            </form>

        </div>

    </div>
</template>

<script>

    import { 
        required,
        minLength
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                form: {
                    email:    this.$route.params.email,
                    token:    this.$route.params.token,
                    username: this.$route.params.username,
                    password: ``
                },
                utilities: {
                    confirm: {
                        password: ``
                    },
                    show: {
                        password:         false,
                        confirm_password: false
                    }
                },
                validation: {
                    ko: false,
                    ok: false
                },
                loaders: {
                    updating: false
                }
            }
        },
        validations() {
            return {
                form: {
                    email: {
                        required
                    },
                    password: {
                        required,
                        minLength: minLength(8),
                        specialChar() {

                            const specialChars = `!#$&()_+-=;':",.?`

                            const result = specialChars.split('').some(specialChar => {

                                if (this.form.password.includes(specialChar)) return true

                                return false

                            })

                            return result

                        }
                    }
                },
                utilities: {
                    confirm: {
                        password: {
                            required,
                            equalsPassword: () => {
                                return this.form.password == this.utilities.confirm.password
                            }
                        }
                    }
                }
            }
        },
        methods: {
            async updatePassword() {

                this.loaders.creating = true
                this.validation.ko    = false
                this.validation.ok    = false

                let message

                try {

                    const res = await this.axios.post(`auth/password/create`, this.form, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    message = res.data.msg

                    this.$toast.open({
                        message: message,
                        type:   `success`
                    })

                    this.validation.ok = message

                }
                catch(e) {

                    message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko    = message

                }
                finally {
                    this.loaders.creating = false
                }
                
            }
        }
    }
</script>