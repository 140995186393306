import axios from 'axios'

const state = {
    token:                    null,
    username:                 null,
    name:                     null,
    surname:                  null,
    user:                     null,
    userType:                 null,
    softwareFunctions:        null,
    authorized:               null,
    pathologyCompetence:      null,
    softwareSpecialFunctions: null,
    status:                   null
}

const getters = {
    isLogged:                 state => !!state.token,
    isAuthorized:             state => state.authorized,
    infoUser:                 state => state,
    userType:                 state => state.userType,
    softwareFunctions:        state => state.softwareFunctions,
    pathologyCompetence:      state => state.pathologyCompetence,
    softwareSpecialFunctions: state => state.softwareSpecialFunctions,
    isBlocked:                state => !state.status
}

const actions = {
    async doCentralLogin({commit}, form) {

        const infoUser = await axios.post(`auth/login`, form)

        await commit(`setInfoUser`,   infoUser.data)
        await commit(`setAuthorized`, infoUser.data)
        
    },
    async doCentralLogout({commit}) {
        commit(`removeInfoUser`)
    }
}

const mutations = {
    setInfoUser(state, info) {
        state.token                    = info.token
        state.username                 = info.username
        state.name                     = info.name
        state.surname                  = info.surname
        state.user                     = info.user
        state.userType                 = info.user.user_type
        state.softwareFunctions        = info.user.user_type.users_software_functions
        state.pathologyCompetence      = info.user.pathology
        state.softwareSpecialFunctions = info.user.user_type.user_types_software_special_functions
        state.status                   = info.user.status
    },
    removeInfoUser(state) {
        state.token                    = null
        state.username                 = null
        state.name                     = null
        state.surname                  = null       
        state.user                     = null
        state.userType                 = null
        state.softwareFunctions        = null
        state.pathologyCompetence      = null
        state.softwareSpecialFunctions = null
        state.status                   = null
    },
    setAuthorized(state, info) {

        // Non è autorizzato a prescindere
        let res = false

        // Se c'è almeno un'autorizzazione, utente autorizzato
        for(let softwareFunction of info.user.user_type.users_software_functions) {
            if(softwareFunction.abilitation) res = true
        }

        state.authorized = res

    }
}

export default {
    state,
    getters,
    actions,
    mutations
}