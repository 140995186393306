<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />
                
                <div class="card card-body">
                    <form @submit.prevent="create_user_software_function" autocomplete="off" v-if="DB.software_functions.length > 0">
                        <div class="row">
                            <div class="col-lg-5">
                                <div class="form-group">

                                    <label for="software_functions">Seleziona una funzionalità</label>

                                    <select class="form-control" id="software_functions" v-model="$v.form.id_software_functions.$model">
                                        <option v-for="software_function of DB.software_functions" :key="`software_function_${software_function.id}`" :value="software_function.id">
                                            {{software_function.title}} ({{software_function.description}})
                                        </option>
                                    </select>

                                    <small class="form-text text-muted">
                                        Seleziona una nuova funzionalità da associare
                                    </small>

                                </div>
                                <div class="custom-control custom-checkbox text-truncate">
                                    <input type="checkbox" class="custom-control-input" id="create" v-model="form.create">
                                    <label class="custom-control-label" for="create">Creazione</label>
                                </div>
                                <div class="custom-control custom-checkbox text-truncate">
                                    <input type="checkbox" class="custom-control-input" id="read" v-model="form.read">
                                    <label class="custom-control-label" for="read">Lettura</label>
                                </div>
                                <div class="custom-control custom-checkbox text-truncate">
                                    <input type="checkbox" class="custom-control-input" id="update" v-model="form.update">
                                    <label class="custom-control-label" for="update">Modifica</label>
                                </div>
                                <div class="custom-control custom-checkbox text-truncate mb-3">
                                    <input type="checkbox" class="custom-control-input" id="delete" v-model="form.delete">
                                    <label class="custom-control-label" for="delete">Eliminazione</label>
                                </div>

                                <button class="btn btn-ifo" :disabled="$v.$invalid || loaders.creating">
                                    Aggiungi
                                </button>

                            </div>
                        </div>
                    </form>
                    <div class="alert alert-danger m-0" v-else>
                        Nessuna funzionalità disponibile
                    </div>
                </div>
            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a href class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom disabled">
                            <i class="text-muted">
                                Nessuna funzione
                            </i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                DB: {
                    software_functions: []
                },
                form: {
                    title:                 this.$route.params.title,
                    abilitation:           true,
                    create:                false,
                    read:                  false,
                    update:                false,
                    delete:                false,
                    id_software_functions: null
                },
                loaders: {
                    creating: false
                }
            }
        },
        validations() {
            return {
                form: {
                    id_software_functions: {
                        required
                    }
                }
            }
        },
        methods: {
            async read_software_functions() {
                try {

                    this.DB.software_functions = (
                        await this.axios.post(`user/software_functions/read/activable`, {title: this.form.title}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                }
            },
            async create_user_software_function() {

                this.loaders.creating = true

                try {
                    
                    await this.axios.post(`user/users_software_functions/create`, this.form, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: "Autorizzazione creata con successo",
                        type:    "success"
                    })

                    this.$router.push(`/user/utenti/tipologie/modifica/${this.form.title}`)

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.loaders.creating = false

                }
            }
        },
        mounted() {
            this.read_software_functions()
        }
    }
</script>