<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />

                <div class="card card-body">

                    <form @submit.prevent="create_user_software_special_function" autocomplete="off" v-if="DB.user_software_special_function.length > 0">
                        <div class="row">
                            <div class="col-lg-5">
                                <div class="form-group">

                                    <label for="software_special_functions">Seleziona una funzionalità speciale</label>

                                    <select class="form-control" id="software_special_functions" v-model="$v.form.id_software_special_function.$model">
                                        <option v-for="software_special_function of DB.user_software_special_function" :key="`software_function_${software_special_function.id}`" :value="software_special_function.id">
                                            {{software_special_function.title}} ({{software_special_function.description}})
                                        </option>
                                    </select>

                                    <small class="form-text text-muted">
                                        Seleziona una nuova funzionalità da associare
                                    </small>

                                </div>

                                <button class="btn btn-ifo" :disabled="$v.$invalid || loaders.creating">
                                    Aggiungi
                                </button>

                            </div>
                        </div>
                    </form>
                    <div class="alert alert-danger m-0" v-else>
                        Nessuna funzionalità disponibile
                    </div>
                </div>
            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a href class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom disabled">
                            <i class="text-muted">
                                Nessuna funzione
                            </i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                DB: {
                    user_software_special_function: []
                },
                form: {
                    title:                        this.$route.params.title,
                    abilitation:                  true,
                    id_software_special_function: null
                },
                loaders: {
                    creating: false
                }
            }
        },
        validations() {
            return {
                form: {
                    id_software_special_function: {
                        required
                    }
                }
            }
        },
        methods: {
            async read_user_type_software_special_function() {
                try {

                    this.DB.user_software_special_function = (
                        await this.axios.post(`user/software_special_functions/read/activable`, {title: this.form.title}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: "error"
                    })

                }
            },
            async create_user_software_special_function() {

                this.loaders.creating = true

                try {
                    
                    await this.axios.post(`user/software_special_functions/create`, this.form, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: "Autorizzazione speciale creata con successo",
                        type:    "success"
                    })

                    this.$router.push(`/user/utenti/tipologie/modifica/${this.form.title}`)

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.loaders.creating = false

                }
            }
        },
        mounted() {
            this.read_user_type_software_special_function()
        }
    }
</script>