const state = {
    charts: {
        bar: {
            options: {
                scales: {
                    y: {
                        stacked: true
                    }
                },
                legend: {
                    display: true
                }
            }
        },       
        line: {
            options: {
                scales: {
                    y: {
                        stacked: true
                    }
                },
                legend: {
                    display: true
                }
            }
        },
        doughnut: {
            options: {

                responsive:          true, 
                maintainAspectRatio: false,
                cutoutPercentage:    80,

                animation: {
                    animateRotate: true
                },
                legend: {
                    display: false
                }

            }
        }
    }
}

const getters = {
    line_options:     state => state.charts.line.options,
    doughnut_options: state => state.charts.doughnut.options
}

export default {
    state,
    getters
}