import Vuex                 from 'vuex'
import Vue                  from 'vue'
import createPersistedState from 'vuex-persistedstate'
import auth                 from './modules/auth'
import charts               from './modules/charts'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        charts
    },
    plugins: [
        createPersistedState()
    ]
})