<template>
    <div class="container-fluid">
        <div class="row">

            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <loader v-if="loaders.reading" />

                <div v-else>

                    <!-- Breadcrumb -->
                    <breadcrumb />

                    <div class="card card-body">

                        <h5 class="font-weight-bold">Modifica le informazioni sull'associazione della patologia con la terapia</h5>

                        <div class="row">
                            <div class="col-lg-3">
                                <form autocomplete="off">

                                    <div class="form-group">

                                        <label for="therapy">Seleziona una terapia</label>

                                        <select class="form-control is-invalid" id="therapy" disabled>
                                            <option selected>
                                                {{form.pathology_therapy.therapy.title}}
                                            </option>
                                        </select>

                                        <small class="text-form text-danger">
                                            L'associazione non può essere modificata
                                        </small>

                                    </div>

                                </form>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a v-b-toggle.elimina v-bind:class="{'disabled': loaders.deleting}" class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom">
                            Elimina <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash ml-2 mt-1" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </a>
                        <b-collapse id="elimina">
                            <div class="alert alert-danger m-0">
                                Sicuro di voler eliminare questa associazione?
                            </div>
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <button @click="delete_pathology_therapy" class="btn btn-ifo btn-block btn-sm rounded-0" :disabled="loaders.deleting">
                                    
                                        <span v-show="!loaders.deleting">
                                            Si
                                        </span>

                                        <div v-show="loaders.deleting" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>
                                </div>
                                <div class="col-6 pl-0">
                                    <button v-b-toggle.elimina class="btn btn-ifo btn-block btn-sm rounded-0">No</button>
                                </div>
                            </div>
                        </b-collapse>
                    </li>
                </ul>
            </div>

        </div>  
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    pathology_therapy: null
                },
                validation: {
                    ko: false
                },
                loaders: {
                    reading:  true,
                    deleting: false
                }
            }
        },
        methods: {
            async create_pathologies_therapies() {

                this.loaders.creating = true
                this.validation.ko    = false

                try {

                    const res = await this.axios.post(`user/pathologies_therapies/create`, this.form, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   `success`
                    })

                    this.$router.go(-1)

                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko    = message
                    this.loaders.creating = false

                }
                
            },
            async read_pathology_therapy() {

                try {
                    const pathology_therapy = await this.axios.get(`user/pathologies_therapies/read/detail/${this.$route.params.id}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.pathology_therapy = pathology_therapy.data

                }
                catch(e) {

                    this.$toast.open({
                        message: `Si è verificato un problema, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}` ,
                        type:    `error`
                    })

                    this.$router.go(-1)

                }
                finally {
                    this.loaders.reading = false
                }

            },
            async delete_pathology_therapy() {

                this.loaders.deleting = true

                try {

                    const res = await this.axios.delete(`user/pathologies_therapies/${this.$route.params.id}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })

                    this.$router.push(`/user/patologie/modifica/${this.$route.params.title}`)
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                    this.loaders.deleting = false

                }

            },
        },
        mounted() {
            this.read_pathology_therapy()
        }
    }
</script>