<template>
    <div class="container-fluid">

        <div class="row">

            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />

                <div class="card card-body">

                    <h5 class="font-weight-bold m-0">Aggiungi una nuova terapia</h5>

                    <p class="text-muted mb-3">
                        <small>
                            Assegna un titolo ed una descrizione per ogni terapia
                        </small>
                    </p>

                    <form @submit.prevent="createTherapy" autocomplete="off">

                        <div class="row">
                            <div class="col-lg-4">
                                    
                                <div class="form-group">

                                    <label for="title">Titolo</label>
                                    <input type="text" class="form-control text-uppercase" id="title" v-model.trim="$v.form.title.$model" v-bind:class="{'is-invalid': !$v.form.title.required && $v.form.title.$dirty}" required>                           
                                    
                                    <p v-if="!$v.form.title.required && $v.form.title.$dirty" class="m-0 text-danger">
                                        <small>
                                            Titolo richiesto
                                        </small>
                                    </p>
                                    <p>
                                        <small class="text-danger">
                                            Attenzione il titolo non potrà essere modificato
                                        </small>
                                    </p>

                                </div>
                                    
                                <div class="form-group">
                                    <label for="description">Descrizione</label>
                                    <div class="input-group">
                                        <textarea class="form-control" id="description" v-model="form.description"></textarea>                           
                                    </div>
                                </div>

                                <div v-show="validation.ko" class="alert alert-danger">
                                    {{validation.ko}}
                                </div>                        
                                
                                <div>
                                    <button @click="createTherapy" class="btn btn-ifo" :disabled="$v.$invalid || loaders.creating">
                                        
                                        <span v-show="!loaders.creating">
                                            Aggiungi
                                        </span>

                                        <div v-show="loaders.creating" class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>

                                    </button>
                                </div>

                            </div>
                        </div>

                    </form>

                </div>
            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a href class="nav-link d-flex align-items-center justify-content-end p-3 a_bg-ifo border-bottom disabled">
                            <i class="text-muted">
                                Nessuna funzione
                            </i>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
        
    </div>
</template>

<script>

    import { 
        required
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                form: {
                    title:       null,
                    description: null
                },
                validation: {
                    ko: false
                },
                loaders: {
                    creating: false
                }
            }
        },
        validations: {
            form: {
                title: {
                    required
                }
            }
        },
        methods: {
            async createTherapy() {
                
                this.loaders.creating = true
                this.validation.ko    = false

                try {
                    const res = await this.axios.post(`user/therapies/create`, this.form, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.$toast.open({
                        message: res.data.msg,
                        type:   'success'
                    })
                    this.$router.push(`/user/terapie`)
                }
                catch(e) {

                    const message = e.response.data.msg

                    this.$toast.open({
                        message,
                        type: 'error'
                    })

                    this.validation.ko    = message
                    this.loaders.creating = false

                }
                
            }
        }
    }
</script>